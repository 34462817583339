import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import './styles/globals.css'
import Home from './main';
import aboutUs from './pages/about';
import management from './pages/management';
import AnnualReport from './pages/dashboard/annualReport';
import Report from './pages/report';
import Rap515 from './pages/products/rapiscan515';
import Rapiscan618xr from './pages/products/rapiscan618xr';
import Rapiscan618xrh from './pages/products/rapiscan618xrh';
import Rap620xr from './pages/products/rapiscan620xr';
import Rap620xrh from './pages/products/rapiscan620xrh';
import Rap620xrve from './pages/products/rapiscan620xrve';
import Rap622xr from './pages/products/rapiscan622xr';
import Rap624xr from './pages/products/rapiscan624xr';
import Rap626xr from './pages/products/rapiscan626xr';
import Rap627xr from './pages/products/rapiscan627xr';
import Rap628xr from './pages/products/rapiscan628xr';
import Rap632xr from './pages/products/rapiscan632xr';
import Rap638xr from './pages/products/rapiscan638xr';
import R918cx from './pages/products/918cx';
import R920cx from './pages/products/920cx';
import R922cx from './pages/products/922cx';
import R920ct from './pages/products/920ct';
import Mobiletrace from './pages/products/mobiletrace';
import Itemiser4DX from './pages/products/itemiser4dx';
import Itemiser3E from './pages/products/itemiser3e';
import Metor6m from './pages/products/metor6m';
import Metor6e from './pages/products/metor6e';
import Metor6wp from './pages/products/metor6wp';
import Rap636sv from './pages/products/rap636sv';
import Service from './pages/service';
import Maintenance from './pages/maintenance';
import Spares from './pages/spares';
import MaintenanceManage from './pages/maintenancemanage';
import Training from './pages/training';
import SafetyAudits from './pages/safetyaudits';
import Opening from './pages/openings';
import EventsCategory from './pages/eventsCategory';
import ContactUs from './pages/contactus';
import EventsCareer from './pages/events';
import Category from './pages/category';
import LoginContainer from './pages/login';
import AddAnnualReport from './pages/dashboard/annualReport/add';
import Careers from './pages/dashboard/career';
import AddCareers from './pages/dashboard/career/addCareers';
import Positions from './pages/dashboard/positions';
import Department from './pages/dashboard/department';
import Locations from './pages/dashboard/locations';
import R920dx from './pages/products/920dx';
import R927dx from './pages/products/927dx';
import R928dx from './pages/products/928dx';
import Itemiser5x from './pages/products/itemiser5x';
import Metor6s from './pages/products/metor6s';
import Metor28 from './pages/products/metor28';
import Metor28e from './pages/products/metor28e';
import Rap620dv from './pages/products/rap620dv';
import Rap627dv from './pages/products/rap627dv';
import Rap628dv from './pages/products/rap628dv';
import Rap632dv from './pages/products/rap632dv';
import Rap638dv from './pages/products/rap638dv';
import RegionsLoc from './pages/dashboard/regions';
import ContactsLoc from './pages/dashboard/contactsLoc';
import ClientsList from './pages/clientslist';
import CSRReport from './pages/dashboard/csr';
import AddCSRReport from './pages/dashboard/csr/add';
import CSR from './pages/csr';
import MatrixInput from './pages/matrixInput';


function App() {
  return (
  <React.Fragment>
     <Router>
     <Switch>
     <Route path='/' exact component={Home} />
     <Route path='/login' exact component={LoginContainer} />
     <Route path='/dashboard/annualReport' exact component={AnnualReport} />
     <Route path='/dashboard/csr' exact component={CSRReport} />
     <Route path='/dashboard/matrixInput' exact component={MatrixInput} />
     <Route path='/dashboard/csr/add' exact component={AddCSRReport} />
     <Route path='/dashboard/csr/add/:id' exact component={AddCSRReport} />
     <Route path='/dashboard/career' exact component={Careers} />
     <Route path='/dashboard/positions' exact component={Positions} />
     <Route path='/dashboard/regions' exact component={RegionsLoc} />
     <Route path='/dashboard/regions/:id' exact component={RegionsLoc} />
     <Route path='/dashboard/contacts' exact component={ContactsLoc} />
     <Route path='/dashboard/department' exact component={Department} />
     <Route path='/dashboard/career/addCareers' exact component={AddCareers} />
     <Route path='/dashboard/career/addCareers/:id' exact component={AddCareers} />
     <Route path='/dashboard/annualReport/add' exact component={AddAnnualReport} />
     <Route path='/dashboard/annualReport/add/:id' exact component={AddAnnualReport} />
     <Route path='/dashboard/locations' exact component={Locations} />
     <Route path='/dashboard/contacts/:id' exact component={ContactsLoc} />
     <Route path='/about' exact component={aboutUs} />
     <Route path='/management' exact component={management} />
     <Route path='/report' exact component={Report} />
     <Route path='/csr' exact component={CSR} />
     <Route path='/products/rapiscan515' exact component={Rap515} />
     <Route path='/products/rapiscan618xr' exact component={Rapiscan618xr} />
     <Route path='/products/rapiscan618xrh' exact component={Rapiscan618xrh} />
     <Route path='/products/rapiscan620xr' exact component={Rap620xr} />
     <Route path='/products/rapiscan620xrh' exact component={Rap620xrh} />
     <Route path='/products/rapiscan620xrve' exact component={Rap620xrve} />
     <Route path='/products/rapiscan622xr' exact component={Rap622xr} />
     <Route path='/products/rapiscan624xr' exact component={Rap624xr} />
     <Route path='/products/rapiscan626xr' exact component={Rap626xr} />
     <Route path='/products/rapiscan627xr' exact component={Rap627xr} />
     <Route path='/products/rapiscan628xr' exact component={Rap628xr} />
     <Route path='/products/rapiscan632xr' exact component={Rap632xr} />
     <Route path='/products/rapiscan638xr' exact component={Rap638xr} />
     <Route path='/products/rap620dv' exact component={Rap620dv} />
     <Route path='/products/rap627dv' exact component={Rap627dv} />
     <Route path='/products/rap628dv' exact component={Rap628dv} />
     <Route path='/products/rap632dv' exact component={Rap632dv} />
     <Route path='/products/rap638dv' exact component={Rap638dv} />
     <Route path='/products/920dx' exact component={R920dx} />
     <Route path='/products/927dx' exact component={R927dx} />
     <Route path='/products/928dx' exact component={R928dx} />
     <Route path='/products/918cx' exact component={R918cx} />
     <Route path='/products/920cx' exact component={R920cx} />
     <Route path='/products/922cx' exact component={R922cx} />
     <Route path='/products/920ct' exact component={R920ct} />
     <Route path='/products/mobiletrace' exact component={Mobiletrace} />
     <Route path='/products/itemiser4dx' exact component={Itemiser4DX} />
     <Route path='/products/itemiser3e' exact component={Itemiser3E} />
     <Route path='/products/itemiser5x' exact component={Itemiser5x} />
     <Route path='/products/metor6m' exact component={Metor6m} />
     <Route path='/products/metor6e' exact component={Metor6e} />
     <Route path='/products/metor6s' exact component={Metor6s} />
     <Route path='/products/metor6wp' exact component={Metor6wp} />
     <Route path='/products/metor28' exact component={Metor28} />
     <Route path='/products/metor28e' exact component={Metor28e} />
     <Route path='/products/rap636sv' exact component={Rap636sv} />
     <Route path='/events/:id' exact component={EventsCareer} />
     <Route path='/category/:id' exact component={Category} />
     <Route path='/category/:id/:product' exact component={Category} />
     <Route path='/service' exact component={Service} />
     <Route path='/maintenance' exact component={Maintenance} />
     <Route path='/spares' exact component={Spares} />
     <Route path='/maintenancemanage' exact component={MaintenanceManage} />
     <Route path='/training' exact component={Training} />
     <Route path='/safetyaudits' exact component={SafetyAudits} />
     <Route path='/openings' exact component={Opening} />
     <Route path='/eventsCategory' exact component={EventsCategory} />
     <Route path='/contactus' exact component={ContactUs} />
     <Route path='/clientslist' exact component={ClientsList} />
     </Switch>
     </Router>
  </React.Fragment>
  );
}

export default App;
