import React,{useEffect,useState} from 'react';
import { Breadcrumb } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBarHeader } from '../../components/navbar';

import styles from './events.module.css';
import { FooterWeb } from '../../components/footer';
import styles1 from '../../components/footer/footer.module.css';
import { ScrollToTop } from '../../components/scrolltotop';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lightgallery-bundle.css';
import useScript from '../../components/useScript';
import {useParams} from 'react-router-dom';
declare const lightGallery:any;
 const EventsCareer = function(){
  let { id }:any = useParams();
  // const useQuery = () => {
  //   const { search } = useLocation();
  //   return React.useMemo(() => new URLSearchParams(search), [search]);
  // }
  // let query = useQuery();
  // const location = useLocation();
  // console.log('Pathname Loc ---->',location);
  let productName = '';
  let productList:any = [];
  if(id){
    if(id==="25Years_Celberation"){
      productName = '25 Years Silver Jubilee Celebrations';
      productList = [
    '/images/events/25Years_Celberation-32.jpg','/images/events/25Years_Celberation-33.jpg',
  '/images/events/25Years_Celberation-34.jpg','/images/events/25Years_Celberation-35.jpg',
'/images/events/25Years_Celberation-36.jpg']
    }
    if(id==="durga_pooja_2019"){
      productName = 'Durga Pooja';
      productList = [
    '/images/events/durga_pooja.jpeg']
    }
    if(id==="ganesh_pooja_2018"){
      productName = 'Ganesh Pooja';
      productList = [
    '/images/events/Ganesha_2018.jpg','/images/events/Ganesha_2019.jpg','/images/events/Ganesha_2020.jpg','/images/events/Ganesha_2021.jpg','/images/events/Ganesha_2022.jpg',
'/images/events/ganesh_pooja.jpg','/images/events/Ganesh_2022.jpg','/images/events/Ganesh_2022_1.jpg','/images/events/Ganesha_2023.jpg','/images/events/Ganesha_2023_2.jpg']
    }
    if(id==="pot_luck_2019"){
      productName = 'Pot Luck';
      productList = [
    '/images/events/pot_luck_2019.jpg','/images/events/Celebrations_2023.jpg']
    }
    if(id==="womens_day_2018"){
      productName = 'Womens Day';
      productList = [
	'/images/events/womens_day_2018.jpg','/images/events/Womens_Day_1.jpg']
    }
    if(id==="birthday_celebration"){
      productName = 'Birthday & New Year Celebrations';
      productList = [
    '/images/events/New_Year_Celebrations_2023.jpg','/images/events/Birthday_Celebration-1.jpg','/images/events/June_2023_Birthday.jpg']
    }
      if (id === "inauguration") {
          productName = 'Inaguration Celebrations-January, 2023';
          productList = [
              '/images/events/ERL_Entrance.JPG', '/images/events/ERL_Godown_Inagrtn.JPG', '/images/events/ERL_Group.JPG']
      }

  }
            
   useScript('https://cdnjs.cloudflare.com/ajax/libs/lightgallery/2.3.0/lightgallery.min.js');   
    useEffect(() => {
      //  loadGallery();
      setTimeout(() => {
        lightGallery(document.getElementById('lightgallery')); 
      }, 1000);
    
    },[useScript])
 

    return (<>        <NavBarHeader></NavBarHeader>
        <section className={styles.breadcrumbs}>
        <div className="container">
 
 <div className="d-flex justify-content-between align-items-center">
     <h2 className={styles.headingH2}>{productName}</h2>
     <Breadcrumb>
         <Breadcrumb.Item href="#">Career</Breadcrumb.Item>
         <Breadcrumb.Item href="#">Events</Breadcrumb.Item>
         <Breadcrumb.Item active>{productName}</Breadcrumb.Item>
     </Breadcrumb>
 </div>

</div>

        </section> 
        <section>
            <div className="container  mb-4">
                <div className="row" id="lightgallery">
   {productList.map((value:any, index:number)=><a key={index} href={value} className={styles.thumbNail} >
        <img src={value} className={`${styles.overflowImg} "img-fluid img-thumbnail"`}/>
    </a>)}          
 
  
      </div></div></section>
        <footer id={styles1.footer}>
        <div className={styles1.footerTop}>
          <div className="container">
            <div className="row">
              <FooterWeb></FooterWeb>
              <div className={styles1.container}>
                <div className={styles1.copyright}>
                  © 2021 <strong><span>ECIL Rapiscan Limited.</span></strong> All Rights Reserved
                </div>

              </div>
            </div>
          </div>
        </div>
      </footer>
      <ScrollToTop></ScrollToTop>
        </>);
}

export default EventsCareer;