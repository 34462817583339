import { Button, Form, Input, PageHeader, Space, Table } from 'antd';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import DashBoardL from '../../../components/dashboardL';
import { openNotificationWithIcon } from '../../../common';
import { config } from '../../../constant';


const Positions = () => {
    const router = useHistory();
    const [dataSource, setDataSource] = useState([]);
    const [token,setToken] = useState<string>('');
    const [form] = Form.useForm();
    const deletePositions = async (id: string) => {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

        
    
                var requestOptions:any = {
                    method: 'POST',
                    headers: myHeaders,
            body:JSON.stringify({
                id:id
            })
                };
            await fetch(config().API_URL+"/DeletePosition", requestOptions)
            .then(response=>response.json())
            .then(async(res: any) => {

            if(res.d){
            openNotificationWithIcon("Success", "Position Deleted Successfully!!!", "");
            FetchData();
            }
        }).catch((err:any)=>{
        
            console.log('Error--->',err);
        })
    }
    const columns = [
        {
            title: 'Name',
            dataIndex: 'posName',
            key: 'posName',

        },
        {
            title: 'Action',
            key: 'action',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Button key="1" onClick={() => deletePositions(record.Id)} type="ghost" danger >

                        Delete
                    </Button>
                </Space>
            ),
        },
    ];
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const FetchData = async (body:any='') => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
        };
        await fetch(config().API_URL+"/GetPosition",requestOptions).then(response=>response.json()).then(async (res: any) => {
            const result = JSON.parse(res.d);
            if (res.d) {
                setDataSource(result);
            }
        }).catch((err: any) => {
            console.log('Error--->', err);
     
        })
    }
    useEffect(()=>{
        const token = localStorage.getItem('token')|| '';
        setToken(token);
        FetchData();
      },[])
    const onFinish = async (values: any) => {
        
        console.log('Success:', values);
        if(values.posName){
            var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");

			var raw = JSON.stringify({
				"posName": values.posName
			});
	
			var requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: raw
			};
			await fetch(config().API_URL+"/AddPosition", requestOptions)
            .then((response)=>response.json()).then((res: any) => {
				if(res.d){
                    openNotificationWithIcon("Success", "Position Added Successfully!!!", "");
                    form.resetFields();
                    FetchData();
				} else if(res.status===200){
                    openNotificationWithIcon("Error", "Authentication Failed", "Invalid Username or Password");
				}
			}).catch((err:any)=>{
				console.log('Error--->',err);
     
			})
        } else {
            openNotificationWithIcon("Error", "Validation Failed", "Please Input all fields");
        }
    }
    return (<><DashBoardL>
        <><PageHeader
            className="site-page-header"

            title="Positions"
            subTitle="View"
        /> <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 4 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
                <Form.Item
                    label="Position"
                    name="posName"
                    rules={[{ required: true, message: 'Enter Position' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ offset:4 , span: 4}}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form> <Table dataSource={dataSource} columns={columns} /></></DashBoardL></>)
}

export default Positions;