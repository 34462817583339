import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { NavBarHeader } from '../../components/navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './contactus.module.css';
import styles1 from '../../components/footer/footer.module.css';
import { FooterWeb } from '../../components/footer';
import { ScrollToTop } from '../../components/scrolltotop';
import { config } from '../../constant';
const ContactUs = () =>{
  const [dataSource, setDataSource] = useState([]);
  const FetchContacts = async (body:any='') => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
    };
    await fetch(config().API_URL+"/GetContact",requestOptions).then(response=>response.json()).then(async (res: any) => {
        const result = JSON.parse(res.d);
        if (res.d) {
            setDataSource(result);
        }
    }).catch((err: any) => {
        console.log('Error--->', err);
 
    })
}
  useEffect(()=>{
    FetchContacts();
  },[])
    return(<React.Fragment>
        <NavBarHeader></NavBarHeader>
        <section className={styles.breadcrumbs}>
        <div className="container">
 
 <div className="d-flex justify-content-between align-items-center">
     <h2 className={styles.headingH2}>Contact Us</h2>
     <Breadcrumb>
         <Breadcrumb.Item href="#">Company</Breadcrumb.Item>
         <Breadcrumb.Item active>Contact Us</Breadcrumb.Item>
     </Breadcrumb>
 </div>

</div>

        </section>
        <section className={styles.resume}>

            <div className="container  my-4">

            <div className={styles.main}>
<h2>SOUTHERN REGION</h2>
</div>

            <div className="row m-4">
 

            {/* <h3 className={styles.resumeTitle}>Education</h3> */}
            {dataSource.map((value:any,index:number)=>{
              if(value?.regName==="SOUTHERN REGION"){
                return(<div className={index>0?"my-2 col-lg-6":"col-lg-6"}><div className={styles.resumeItem}>
                  <h4>{value.conName}</h4>
                  <p dangerouslySetInnerHTML={{__html: value.description.replace(/\n/g, "<br />") }}></p>
                  
                </div></div>)
              } else return null;
             
            })}
  

        </div>
            </div>
        </section>
<section className={styles.resume}>

<div className="container  my-4">

<div className={styles.main}>
<h2>NORTHERN REGION</h2></div>

<div className="row m-4">
           
{dataSource.map((value:any,index:number)=>{

if(value?.regName==="NORTHERN REGION"){
  return(<div className={index>0?"my-2 col-lg-6":"col-lg-6"}><div className={styles.resumeItem}>
    <h4>{value.conName}</h4>
    <p dangerouslySetInnerHTML={{__html: value.description.replace(/\n/g, "<br />") }}></p>
    
  </div></div>)
} else return null;

})}
  
      
         
         </div></div>
</section>
<section className={styles.resume}>

<div className="container  my-4">

<div className={styles.main}>
<h2>WESTERN REGION</h2></div>

<div className="row m-4">
      
{dataSource.map((value:any,index:number)=>{
    
    if(value?.regName==="WESTERN REGION"){
      return(<div className={index>0?"my-2 col-lg-6":"col-lg-6"}><div className={styles.resumeItem}>
        <h4>{value.conName}</h4>
        <p dangerouslySetInnerHTML={{__html: value.description.replace(/\n/g, "<br />") }}></p>
        
      </div></div>)
    } else return null;
   
  })}
      </div></div>
</section>
<section className={styles.resume}>

<div className="container  my-4">

<div className={styles.main}>
<h2>CENTRAL REGION</h2></div>

<div className="row m-4">
{dataSource.map((value:any,index:number)=>{
    
    if(value?.regName==="CENTRAL REGION"){
      return(<div className={index>0?"my-2 col-lg-6":"col-lg-6"}><div className={styles.resumeItem}>
        <h4>{value.conName}</h4>
        <p dangerouslySetInnerHTML={{__html: value.description.replace(/\n/g, "<br />") }}></p>
        
      </div></div>)
    } else return null;
   
  })}</div></div></section>
<section className={styles.resume}>

<div className="container  my-4">

<div className={styles.main}>
<h2>EASTERN REGION</h2></div>

<div className="row m-4">
{dataSource.map((value:any,index:number)=>{
    
    if(value?.regName==="EASTERN REGION"){
      return(<div className={index>0?"my-2 col-lg-6":"col-lg-6"}><div className={styles.resumeItem}>
        <h4>{value.conName}</h4>
        <p dangerouslySetInnerHTML={{__html: value.description.replace(/\n/g, "<br />") }}></p>
        
      </div></div>)
    } else return null;
   
  })}</div></div>
</section>
<footer id={styles1.footer}>
        <div className={styles1.footerTop}>
          <div className="container">
            <div className="row">
              <FooterWeb></FooterWeb>
              <div className={styles1.container}>
                <div className={styles1.copyright}>
                  © 2021 <strong><span>ECIL Rapiscan Limited.</span></strong> All Rights Reserved
                </div>

              </div>
            </div>
          </div>
        </div>
      </footer>

      <ScrollToTop></ScrollToTop>
    </React.Fragment>);
};

export default ContactUs;