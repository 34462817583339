import React, { useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { NavBarHeader } from '../../../components/navbar';
import styles from './920cx.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckDouble, faCircle, faDownload} from '@fortawesome/free-solid-svg-icons';
import { FooterWeb } from '../../../components/footer';
import styles1 from '../../../components/footer/footer.module.css';
import { ScrollToTop } from '../../../components/scrolltotop';
import ProductCustomer from '../../../components/productcustomer';
const R920cx = () =>{
  const[opens,setOpens] = useState<string>('');
const removeDownload = () =>{
  setOpens('');
}
  const download =() =>{
    setOpens('/images/productDataSheet/DSBP-0005-920CX-184302.pdf');
  }

    return(<><NavBarHeader></NavBarHeader>
        <section className={styles.breadcrumbs}>
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                    <h2 className={styles.headingH2}>920CX</h2>
                    <Breadcrumb  className="d-none d-sm-block">
                        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="#">Products</Breadcrumb.Item>
                        <Breadcrumb.Item active>baggage and parcel inspection</Breadcrumb.Item>
                        <Breadcrumb.Item active>920CX</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

            </div>
        </section>
        <section id="about" className="about">
      <div className="container aos-init aos-animate" data-aos="fade-up">

   

        <div className="row my-4">
          <div className="col-lg-6 text-center">
           
            <img src="/images/products/920CX.jpg" className="img-fluid pt-3" alt=""/><br/>
            <a href="#" onClick={download} style={{"margin":"0 auto"}} className="btn btn-primary btn-lg"><FontAwesomeIcon icon={faDownload}  /> Data Sheet</a>
            </div>
          
          
          <div className="col-lg-6 pt-4 pt-lg-0 content">
            <p className={styles.productdescr}>The 920CX is a 620mm x 420mm single view screening system providing exceptional threat detection and high throughput by virtue of Best in Industry Penetration and Resolution.</p>
            <p className={styles.productdescr}>ORION 920CX provides the best measured steel penetration, wire resolution and spatial resolution vs. the stated performance of any other X-ray screening system in the industry. With superior image quality & exceptional threat detection it is ideal for high throughput checkpoint environments.


</p>
            <div className="row">
              <div className="col-lg-12">
              <ul className={`${styles.productslist} list-group list-group-flush`}>
    <li className="list-group-item"><FontAwesomeIcon icon={faCheckDouble} className="text-primary"  /> <span> BEST IN CLASS WIRE RESOLUTION</span></li>
    <li className="list-group-item"><FontAwesomeIcon icon={faCheckDouble} className="text-primary"  /> <span>BEST IN CLASS SPATIAL RESOLUTION</span></li>
    <li className="list-group-item"><FontAwesomeIcon icon={faCheckDouble} className="text-primary"  /> <span>BEST IN CLASS STEEL PENETRATION</span></li>
    <li className="list-group-item"><FontAwesomeIcon icon={faCheckDouble} className="text-primary"  /> <span>BEST IN CLASS MATERIAL DISCRIMINATION</span></li>
    <li className="list-group-item"><FontAwesomeIcon icon={faCheckDouble} className="text-primary"  /> <span>EXPLOSIVES & NARCOTICS DETECTION</span></li>
    <li className="list-group-item"><FontAwesomeIcon icon={faCheckDouble} className="text-primary"  /> <span>25% LOWER OPERATING POWER CONSUMPTION</span></li>
     </ul> 
            </div>
            </div>
   
          </div>
        </div>

      </div>
    </section>
    <section>
        <div className="container">
            <div className="row">
                    <div className="col-md-2 col-12">
                    <div className={`${styles.circleIcon}`}>
                
                        <FontAwesomeIcon icon={faCheck} className="text-center"  color="white" size="2x"  />
                        
                    </div>
                    <p className={`${styles.circleIconp} text-center mt-3`}>REGULATORY<br/> BASED EXPLOSIVES<br/> DETECTION<br/> ALGORITHMS</p>
                    </div>
                    <div className="col-md-2 col-12">
                    <div className={`${styles.circleIcon} ${styles.circleIconp}`}>
                
                        <FontAwesomeIcon icon={faCheck} className="text-center"   color="white" size="2x"  />
                        
                    </div>
                    <p className={`${styles.circleIconp} text-center mt-3`}>17 IMAGE <br/>PROCESSING <br/>FUNCTIONS</p>
                    </div>
                    <div className="col-md-2 col-12">
                    <div className={`${styles.circleIcon} ${styles.circleIconp}`}>
                
                        <FontAwesomeIcon icon={faCheck} className="text-center"   color="white" size="2x"  />
                        
                    </div>
                    <p className={`${styles.circleIconp} text-center mt-3`}>SINERGY LOW<br/>DENSITY<br/>ENHANCEMENT</p>
                    </div>
                    <div className=" col-md-2 col-12">
                    <div className={`${styles.circleIcon} ${styles.circleIconp}`}>
                
                        <FontAwesomeIcon icon={faCheck} className="text-center"   color="white" size="2x"  />
                        
                    </div>
                    <p className={`${styles.circleIconp} text-center mt-3`}>AUTOMATIC,<br/> ENHANCED IMAGE<br/> ARCHIVING</p>
                    </div>
                    <div className=" col-md-2 col-12">
                    <div className={`${styles.circleIcon} ${styles.circleIconp}`}>
                
                        <FontAwesomeIcon icon={faCheck} className="text-center"   color="white" size="2x"  />
                        
                    </div>
                    <p className={`${styles.circleIconp} text-center mt-3`}>TRAY RETURN<br/> SYSTEM<br/> COMPATIBLE</p>
                    </div>
                    <div className=" col-md-2 col-12">
                    <div className={`${styles.circleIcon} ${styles.circleIconp}`}>
                
                        <FontAwesomeIcon icon={faCheck} className="text-center"   color="white" size="2x"  />
                        
                    </div>
                    <p className={`${styles.circleIconp} text-center mt-3`}>MATRIXING,<br/> NETWORKING &<br/> REMOTE VIEWING</p>
                    </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
              <h3 className={styles.aboutH3}>SPECTRUM 4-COLOR IMAGING</h3>
              <p  className={styles.listPara}>920CX now can exhibit images in the Classic 4-color and the new proprietary Spectrum 4-color (SP4) option providing superior image, allowing improved security by quick and accurate identification of threats and increase in throughput.</p>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
              <h3 className={styles.aboutH3}>DUAL MODE AUTO ZEFF </h3>  
              <p className={styles.listPara}>The new dual mode Zeff feature helps with identification of organic materials accurately and quickly either in Range mode which highlights the areas based on range selected by the operator, and/or in Interactive mode which provides the operator the option to display the areas based on the value of the pixel. </p>
             </div>    
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
              <h3 className={styles.aboutH3}>EXPLOSIVES AND NARCOTICS DETECTION</h3>
              <p  className={styles.listPara}>
              Target and NARCScan are designed to detect a wide range of explosives and narcotics respectively in real time during the scanning process by marking a potential threat on the X-ray image. Rapiscan Systems detection algorithms are based on regulatory approved material analysis techniques.</p>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
              <h3 className={styles.aboutH3}>EUROPEAN AVIATION COMPLIANCE</h3>  
              <p  className={styles.listPara}>920CX performance is in full compliance with the mandatory regulations (EC) No. 300/2008, (EU) No. 185/2010 and commissions decision C(2010)774 for aviation security in the European Union.</p>
                </div>
            </div>
            <section id="about" className="about my-3">
            <div className="section-title1 my-5"><h2>SPECIFICATION</h2>
            <div className="row my-5">
              <div className="col-lg-3">
              <h5>TUNNEL SIZE</h5>
              <p  className={styles.listPara1}>620 mm x 420 mm (24.4 in x 16.5 in)</p>
              </div>
              <div className="col-lg-3">
              <h5>LENGTH</h5>
              <p  className={styles.listPara1}>2,082 mm (82. in)</p>
              </div>
              <div className="col-lg-3">
              <h5>WIDTH</h5>
              <p  className={styles.listPara1}>869 mm (34.2 in)</p>
              </div>
              <div className="col-lg-3">
              <h5>HEIGHT</h5>
              <p  className={styles.listPara1}>1,377 mm (54.2 in)</p>
              </div>
            </div>
            </div>

            </section>
        </div>
    </section>
    <footer id={styles1.footer}>
        <div className={styles1.footerTop}>
          <div className="container">
            <div className="row">
              <FooterWeb></FooterWeb>
              <div className={styles1.container}>
                <div className={styles1.copyright}>
                  © 2021 <strong><span>ECIL Rapiscan Limited.</span></strong> All Rights Reserved
                </div>

              </div>
            </div>
          </div>
        </div>
      </footer>
      {opens && <ProductCustomer removeDownload={removeDownload} shows={opens} product="920CX"></ProductCustomer>}  
      <ScrollToTop></ScrollToTop>
        </>)
};

export default R920cx;