import { Button, Form, Input, PageHeader, Select, InputNumber, Table } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useHistory, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import DashBoardL from '../../../../components/dashboardL';
import { openNotificationWithIcon } from '../../../../common';
import { config } from '../../../../constant';

const AddCareers = () => {
    const [fetchDep, setFetchDep] = useState<Array<any>>();
    const [fetchPos, setFetchPos] = useState<Array<any>>();
    const [fetchLoc, setFetchLoc] = useState<Array<any>>();
    const history = useHistory();
    let { id }: any = useParams();
    const [form] = Form.useForm()

    const FetchPosition = async (body: any = '') => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
        };
        await fetch(config().API_URL + "/GetPosition", requestOptions)
            .then(response => response.json())
            .then(async (res: any) => {
                if (res.d) {
                    const reportResult = JSON.parse(res.d);
                    let reportArray: any = [];
                    await reportResult.forEach((value: any) => {
                        let reportObj: any = {};
                        reportObj["value"] = value["posName"];
                        reportObj["label"] = value["posName"];
                        reportArray.push(reportObj);
                    })
                    setFetchPos(reportArray);
                }
            }).catch((err: any) => {
                console.log('Error--->', err);

            })
    }

    const FetchCareers = async (id: string) => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
                id: id
            })
        };
        await fetch(config().API_URL + "/GetCareersID", requestOptions)
            .then(response => response.json())
            .then(async (res: any) => {
                if (res.d) {
                    const reportResult = JSON.parse(res.d);
                    if (Array.isArray(reportResult) && reportResult.length > 0) {
                        console.log('Report result--->');
                        form.setFieldsValue({
                            referID: reportResult[0].referID,
                            position: reportResult[0].position,
                            location: reportResult[0].location,
                            department: reportResult[0].department,
                            jobDescr: reportResult[0].jobDescr,
                            noPos: reportResult[0].noPos
                          });
                    }
                }
            }).catch((err: any) => {
                console.log('Error--->', err);

            })
    }
    const FetchLocation = async (body: any = '') => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
        };
        await fetch(config().API_URL + "/GetLocations", requestOptions)
            .then(response => response.json())
            .then(async (res: any) => {
                if (res.d) {
                    const reportResult = JSON.parse(res.d);
                    let reportArray: any = [];
                    await reportResult.forEach((value: any) => {
                        let reportObj: any = {};
                        reportObj["value"] = value["locName"];
                        reportObj["label"] = value["locName"];
                        reportArray.push(reportObj);
                    })
                    setFetchLoc(reportArray);
                }
            }).catch((err: any) => {
                console.log('Error--->', err);

            })
    }
    useEffect(() => {
        FetchDepartment();
        FetchPosition();
        FetchLocation();
        FetchCareers(id);
    }, [])
    const FetchDepartment = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
        };
        await fetch(config().API_URL + "/GetDepartment", requestOptions)
            .then(response => response.json())
            .then(async (res: any) => {
                if (res.d) {
                    const reportResult = JSON.parse(res.d);
                    let reportArray: any = [];
                    await reportResult.forEach((value: any) => {
                        let reportObj: any = {};
                        reportObj["value"] = value["depName"];
                        reportObj["label"] = value["depName"];
                        reportArray.push(reportObj);
                    })
                    setFetchDep(reportArray);
                }
            }).catch((err: any) => {
                console.log('Error--->', err);

            })
    }
    const onFinish = async (values: any) => {
        console.log('Success:', values);
        console.log('Success:', values);
        if (values.department && values.jobDescr && values.location && values.noPos
            && values.position && values.referID) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var requestOptions:any = '';
            var url = '';
            if(id){
                 requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify({...values, id: id})
                };
                url = config().API_URL + "/UpdateCareers"
            } else {
                requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(values)
                };
                url = config().API_URL + "/AddCareers"
            }
           
            await fetch(url, requestOptions).then(response => response.json()).then((res: any) => {
                if (res.d) {
                    openNotificationWithIcon("Success", "Careers Added Successfully!!!", "");
                    history.push('/dashboard/career')
                } else {
                    openNotificationWithIcon("Error", "Authentication Failed", "Invalid Username or Password");
                }
            }).catch((err: any) => {
                console.log('Error--->', err);
            })
        } else {
            openNotificationWithIcon("Error", "Validation Failed", "Please Input all fields");
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    return (<><DashBoardL>
        <><PageHeader
            className="site-page-header"

            title="Careers"
            subTitle="View"
        /> <Form
            form={form}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
                <Form.Item
                    label="Refer ID"
                    name="referID"
                    rules={[{ required: true, message: 'Please input Referral ID!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item name="position" label="Position" rules={[{ required: true, message: 'Select Position' }]}>
                    <Select options={fetchPos} />
                </Form.Item>
                <Form.Item name="location" label="Location" rules={[{ required: true, message: 'Select Location' }]}>
                    <Select options={fetchLoc} />
                </Form.Item>
                <Form.Item name="department" label="Department" rules={[{ required: true, message: 'Select Department' }]}>
                    <Select options={fetchDep} />
                </Form.Item>
                <Form.Item name="jobDescr" label="Job Description" rules={[{ required: true, message: 'Job Description Required' }]}>
                    <TextArea rows={12} />
                </Form.Item>

                <Form.Item label="Positions" name="noPos" rules={[{ required: true, message: 'No of Positions Required' }]}>
                    <InputNumber />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form></></DashBoardL></>)
}

export default AddCareers;