import React from 'react';
import { NavBarHeader } from '../../components/navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FooterWeb } from '../../components/footer';
import { Breadcrumb } from 'react-bootstrap';
import styles from './about.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import styles1 from '../../components/footer/footer.module.css';
import { ScrollToTop } from '../../components/scrolltotop';
const aboutUs = () =>{
    return(<React.Fragment>
        <NavBarHeader></NavBarHeader>
        <section className={styles.breadcrumbs}>
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                    <h2 className={styles.headingH2}>About Us</h2>
                    <Breadcrumb>
                        <Breadcrumb.Item href="#">Company</Breadcrumb.Item>
                        <Breadcrumb.Item active>About</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

            </div>
        </section>
        <section>
            <div className="container  mb-4">
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className={styles.aboutH3}>Pioneer in Public Safety & Security past 25+ Years</h3>
                        <p className={styles.aboutp}><b>ECIL Rapiscan Ltd,</b> incorporated in the year 1995(and is a Joint Venture Company formed jointly by OSI Systems, Inc., USA and Electronics Corporation of India Limited, a wholly-owned Government of India Enterprise)</p>
                        <p className={styles.aboutp}>ECIL Rapiscan Limited, since its inception, we have been selling and providing after-sales services of x-ray baggage inspection systems (XBIS). The technology for the x-ray baggage inspection system is provided by Rapiscan Systems (a subsidiary of OSI Systems, Inc.). In a span of two and half decades, the company has established itself as a &lsquo;Leader&rsquo; in Indian security domain with distinctive innovations supported by Rapiscan Systems from time to time.</p>
                        <p className={styles.aboutp}>Rapiscan Systems, is a leading supplier of screening solutions and offers world-class security systems to various sectors like governments, corporations, law enforcement, and military organizations worldwide. Combat terrorism, drug, and weapons smuggling, illegal immigration, and trade fraud with speed and confidence while maximizing operational efficiency and meeting the most stringent security regulations and standards.</p>
                        <p className={styles.aboutp}>Rapiscan Systems has a state-of-art Research & Development facility which works on the latest software technology to manage supply standards and study screening solutions for our customers worldwide.</p>
                        <p className={styles.aboutp}>The Joint Venture Company can supply standard and sturdy screening solutions to our Indian customers.Our expert areas are X-Ray Baggage Screening, People Screening, Radiation Detection, Metal Detection, Cargo Vehicle Inspection, and Bomb Disposal Solutions.</p>
                        <p className={styles.aboutp}>Our core strength is the latest International Technology adopted by our parent company, Rapiscan Systems. Our qualified and trained team of engineers ensure after-sales services and can respond to service calls within 24 hours turnaround time in more than 27 different centers located all over India; we assure a 24/7 customer-care service through our call center.</p>
                        <p className={styles.aboutp}>We are certified as ISO-9001:2015 compliant by DNV-GL, Our X-Ray based Baggage Inspection Systems are certified Radioactivity tested by the Atomic Energy Regulatory Board (AERB) and approved by BARC for food and film safety security while scanning.</p>
                        <p className={styles.aboutp}>We are a Leader in this Industry for the past 25+ years; our advanced X-Ray based Baggage Inspection machines are installed and are in operation all over India, with more than 3000+ happy customers.</p>
                        <h3 className={styles.sechead}>Our Prestigious Clients include:</h3>
                     
                        </div>
                        <div className="col-lg-6">
                        <ul className={styles.ulList}>
                  <li>
                  <FontAwesomeIcon icon={faCheck}  /> Indian Police & Central Jails
                  </li>
                  <li>
                  <FontAwesomeIcon icon={faCheck}  />  Defence
                  </li>
                  <li>
                  <FontAwesomeIcon icon={faCheck}  />  Airforce stations
                  </li>
                  <li>
                  <FontAwesomeIcon icon={faCheck}  />   Airports
                  </li>
                  <li>
                  <FontAwesomeIcon icon={faCheck}  />  Parliament House
                  </li>
                  <li>
                  <FontAwesomeIcon icon={faCheck}  />  PWD
                  </li>
                  <li>
                  <FontAwesomeIcon icon={faCheck}  />  Customs
                  </li>
                  </ul>
                  </div>
                  <div className="col-lg-6">
                  <ul  className={styles.ulList}>
                
                  <li>
                  <FontAwesomeIcon icon={faCheck}  />  Banking
                  </li>
                  <li>
                  <FontAwesomeIcon icon={faCheck}  />  Railways
                  </li>
                  <li>
                  <FontAwesomeIcon icon={faCheck}  />  Temples
                  </li>
                  <li>
                  <FontAwesomeIcon icon={faCheck}  />  Hotels
                  </li>
                  <li>
                  <FontAwesomeIcon icon={faCheck}  />  Logistics
                  </li>
                  <li>
                  <FontAwesomeIcon icon={faCheck}  />   Corporates and many more
                  </li>
                  </ul>
                  </div>
                  <div className="col-lg-12">
                        <h3 className={styles.aboutH3}>About Rapiscan Systems</h3>
                        <p className={styles.aboutp}><b>Rapiscan Systems,</b> UK & USA (www.rapiscansystems.com) is a subsidiary of OSI Systems Inc., USA. Rapiscan Systems is a leader in manufacturing the latest state-of-the-art XBIS (X-ray and Baggage Screening Solutions) and other related products manufactured to International Standards and approved by the Aviation Authorities in the USA, UK, and EUROPE. Rapiscan has supplied thousands of XBIS to reputed Organizations such as Heathrow Airport, Manchester Airport, Foreign Post Office UK, Her Majesty&apos;s Prisons, Buckingham Palace, and many other USA, Europe, Middle East, South East Asia etc.</p>
                        <h3 className={styles.aboutH3}>About Electronics Corporation of India Limited</h3>
                        <p className={styles.aboutp}><b>Electronics Corporation of India Limited, </b>(www.ecil.co.in) is a wholly-owned Government of India Enterprise and was established in 1967 to create a strong indigenous base in Electronics. ECIL has played a pioneering role in spurring the growth of the electronics industry in India. Over the years, ECIL has evolved into a multi-product, multi-disciplinary organization. The current focus is on increasing contributions to Atomic Energy Sector, Space, Defence Sector, Electronic Security Applications, Communications & Networks, E-Governance Applications and Exports. The company has a man power strength of   1653 employees of which 1050 are executives and 603 are workmen  and a current turnover of around Rs.  1,504 Crore. The Strategic Alliances forged with organizations like Defence Research and Development Organization (DRDO), Bhabha Atomic Research Center (BARC), Nuclear Power Corporation of India Limited (NPCIL), Indira Gandhi Centre for Atomic Research (IGCAR) and Department of Space bear testimony to ECIL&apos;s commitment to the nation.</p>
                </div>
           
                </div>
            </div>
        </section>
        <footer id={styles1.footer}>
        <div className={styles1.footerTop}>
          <div className="container">
            <div className="row">
              <FooterWeb></FooterWeb>
              <div className={styles1.container}>
                <div className={styles1.copyright}>
                  © 2022 <strong><span>ECIL Rapiscan Limited.</span></strong> All Rights Reserved
                </div>

              </div>
            </div>
          </div>
        </div>
      </footer>


      <ScrollToTop></ScrollToTop>
    </React.Fragment>)
} 






export default aboutUs;