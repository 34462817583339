import { Button, PageHeader, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import DashBoardL from '../../../components/dashboardL';
import { useHistory  } from 'react-router-dom';
import { openNotificationWithIcon } from '../../../common';
import { config } from '../../../constant';

const AnnualReport = () => {
  const router = useHistory ();
  const [dataSource,setDataSource] = useState([]);
    const AddAnnualReport = () =>{
      router.push('/dashboard/annualReport/add')
    }
    const deleteAnnualReport = async(id:string) =>{
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
			var requestOptions:any = {
				method: 'POST',
				headers: myHeaders,
        body:JSON.stringify({
          id:id
        })
			};
      await fetch(config().API_URL+"/DeleteReport", requestOptions).then(response => response.json()).then(async(res: any) => {
        const result = res;
       if(result.d){
        openNotificationWithIcon("Success", "Reports Deleted Successfully!!!", "");
        FetchData();
       }
     }).catch((err:any)=>{
       console.log('Error--->',err);
     })
    }

    const editAnnualReport = (number:string) =>{
      router.push('/dashboard/annualReport/add/'+number);
    }
    const FetchData = async() =>{
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

			var requestOptions = {
				method: 'GET',
				headers: myHeaders
			};
      await fetch(config().API_URL+"/Addannualreport", requestOptions)
      .then(response=>response.json()).then(async(res: any) => {

       if(res.d){
        const respResult= JSON.parse(res.d);
        const finalResult = respResult.sort((a:any,b:any)=>parseInt(b.year)-parseInt(a.year))
        setDataSource(finalResult);
       }
     }).catch((err:any)=>{
       console.log('Error--->',err);
     })
    }
    useEffect(()=>{
      FetchData();
    },[])

      
      const columns = [
        {
          title: 'Title',
          dataIndex: 'title',
          key: 'title',
          
        },
        {
          title: 'Year',
          dataIndex: 'year',
          key: 'year',
        },

        {
            title: 'Action',
            key: 'action',
            render: (text:any, record:any) => (
    
              <Space size="middle">
                <Button key="1" type="default" onClick={()=>editAnnualReport(record.Id)}>
                Edit
            </Button>
            <Button key="1" onClick={()=>deleteAnnualReport(record.Id)} type="ghost" danger >
                
                Delete
            </Button>
              </Space>
            ),
          },
      ];
    return (<><DashBoardL>
        <>
        <PageHeader
            className="site-page-header"
            extra={[<Button key="1" type="primary" onClick={AddAnnualReport}>
                Add
            </Button>]}
            title="Annual Report"
            subTitle="View"
        /><Table dataSource={dataSource} columns={columns} /></>
    </DashBoardL>;</>)
}

export default AnnualReport;