import { notification } from 'antd';
export const openNotificationWithIcon = (status: string, title: string, descr: string) => {
    if (status === "Error") {
        notification.error({
            message: title,
            description: descr
        });
    } else  if (status === "Success") {
        notification.success({
            message: title,
            description: descr
        });
    }

};

export function downloadURI(uri:any, name:string) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }