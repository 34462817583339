import { Button, PageHeader, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import DashBoardL from '../../../components/dashboardL';
import { useHistory  } from 'react-router-dom';
import { openNotificationWithIcon } from '../../../common';
import { config } from '../../../constant';
import _ from 'lodash';
const CSRReport = () => {
  const router = useHistory ();
  const [dataSource,setDataSource] = useState([]);
    const AddAnnualReport = () =>{
      router.push('/dashboard/csr/add')
    }
    const deleteAnnualReport = async(id:string, year:string) =>{
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
			var requestOptions:any = {
				method: 'POST',
				headers: myHeaders,
        body:JSON.stringify({
          id:id,
          year:year
        })
			};
      await fetch(config().API_URL+"/DeleteCSRReport", requestOptions).then(response => response.json()).then(async(res: any) => {
        const result = res;
       if(result.d){
        openNotificationWithIcon("Success", "Reports Deleted Successfully!!!", "");
        FetchData();
       }
     }).catch((err:any)=>{
       console.log('Error--->',err);
     })
    }

    const editAnnualReport = (number:string) =>{
      router.push('/dashboard/csr/add/'+number);
    }
    const FetchData = async() =>{
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

			var requestOptions = {
				method: 'GET',
				headers: myHeaders
			};
      await fetch(config().API_URL+"/AddCSRreport", requestOptions)
      .then(response=>response.json()).then(async(res: any) => {

       if(res.d){
        const respResult= JSON.parse(res.d);
        if(Array.isArray(respResult)){
        
            const year:any =  _.groupBy(respResult, 'year');
           const finalArray:any =  Object.keys(year).map((value:any)=>{
                const object:any ={
                    year:"",
                    files:[],
                    Id:0
                };
                year[value].forEach((values:any)=>{
                    object.year = parseInt(value);
                    object.files.push(values.files);
                    object.Id = values.Id;
                })
                return object;
            });
            const finalValues = finalArray.sort((a:any,b:any)=>{return (b.year-a.year)});
            setDataSource(finalValues);
        }

       }
     }).catch((err:any)=>{
       console.log('Error--->',err);
     })
    }
    const download_files =(files:any) => {

        // const download_next =(i:any) =>{
        //  if (i >= files.length) {
        //    return;
        //  }
        //  var a:any = document.createElement('a');
        //  a.href = config().UPLOAD_URL+"/DownloadFile.ashx?id="+ files[i];
        //  a.target = '_blank';
        
        //  if ('download' in a) {
        //    a.download = files[i];
        //  }
        
        //  (document.body || document.documentElement).appendChild(a);
        //  if (a.click) {
        //    a.click(); // The click method is supported by most browsers.
        //  }
        //  else {
        //     window.open(files[i]);
        //  }
        //  console.log('1');
        //  a.parentNode.removeChild(a);
        //  setTimeout(function() {
        //    download_next(i + 1);
        //  }, 5000);
        // }
        // // Initiate the first download.
        // download_next(0);


        var a:any = document.createElement('a');
         a.href = config().UPLOAD_URL+"/DownloadFile.ashx?id="+ files;
         a.target = '_blank';
                  if (a.click) {
           a.click(); // The click method is supported by most browsers.
         }
        }
 
    useEffect(()=>{
      FetchData();
    },[])

      
      const columns = [
        {
          title: 'Year',
          dataIndex: 'year',
          key: 'year',
          
        },
        {
            title: 'Files',
            render: (text:any, record:any) => (
                <Space size="middle">
                  {Array.isArray(record.files) && record.files.length>0 && record.files.map((value:any)=>{
  return(<Button key="1" type="default" onClick={()=>download_files(value)}>
  {value}
</Button>)
                  })}
              
            </Space>
            ),
            key: 'files',
          },
        {
            title: 'Action',
            key: 'action',
            render: (text:any, record:any) => (
    
              <Space size="middle">
                <Button key="1" type="default" onClick={()=>editAnnualReport(record.Id)}>
                Edit
            </Button>
            <Button key="1" onClick={()=>deleteAnnualReport(record.Id, record.year)} type="ghost" danger >
                
                Delete
            </Button>
              </Space>
            ),
          },
      ];
    return (<><DashBoardL>
        <>
        <PageHeader
            className="site-page-header"
            extra={[<Button key="1" type="primary" onClick={AddAnnualReport}>
                Add
            </Button>]}
            title="Corporate Social Responsibility"
            subTitle="View"
        /><Table dataSource={dataSource} columns={columns} /></>
    </DashBoardL>;</>)
}

export default CSRReport;