import { Button, Form, Input, PageHeader, Space, Table } from 'antd';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import DashBoardL from '../../../components/dashboardL';
import { openNotificationWithIcon } from '../../../common';
import { config } from '../../../constant';



const Careers = () => {
    const router = useHistory();
    const [dataSource, setDataSource] = useState<Array<any>>([]);
    const addCareers = () => {
        router.push('/dashboard/career/addCareers')
    }
    const deleteCareers = async (id: string) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var requestOptions: any = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
                id: id
            })
        };
        debugger;
        await fetch(config().API_URL+"/DeleteCareers", requestOptions)
        .then((response)=>response.json())
        .then(async (res: any) => {
            if (res.d) {
                openNotificationWithIcon("Success", "Careers Deleted Successfully!!!", "");
                FetchData();
            }
        }).catch((err: any) => {
            console.log('Error--->', err);
 
        })
    }
    const editCareers = (id: string) => {
        router.push('/dashboard/career/addCareers/' + id);
    }
    const columns = [
        {
            title: 'Refer ID',
            dataIndex: 'referID',
            key: 'referID',

        },
        {
            title: 'Position',
            dataIndex: 'position',
            key: 'position',

        }, {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',

        }, {
            title: 'Department',
            dataIndex: 'department',
            key: 'department',

        },
        {
            title: 'Action',
            key: 'action',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Button key="1" onClick={() => deleteCareers(record.Id)} type="ghost" danger >
                        Delete
                    </Button>
                    <Button key="2" onClick={() => editCareers(record.Id)} type="ghost" >
                        Edit
                    </Button>
                </Space>
            ),
        },
    ];
    const FetchData = async (body: any = '') => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
        };
        await fetch(config().API_URL+"/GetCareers", requestOptions)
        .then(response => response.json())
        .then(async (res: any) => {
            if (res.d) {
                const reportResult = JSON.parse(res.d);
                setDataSource(reportResult);
            }
        }).catch((err: any) => {
            console.log('Error--->', err);
        })
    }
    useEffect(() => {
        FetchData();
    }, [])
    return (<><DashBoardL>
        <><PageHeader
            className="site-page-header"
            extra={[<Button key="1" type="primary" onClick={addCareers}>
                Add
            </Button>]}
            title="Careers"
            subTitle="View"
        />
            <Table dataSource={dataSource} columns={columns} />
        </></DashBoardL></>)
}

export default Careers;