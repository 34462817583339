import React, { useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { NavBarHeader } from '../../../components/navbar';
import styles from './rap638dv.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckDouble, faCircle, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FooterWeb } from '../../../components/footer';
import styles1 from '../../../components/footer/footer.module.css';
import ProductList from '../../../components/productList';
import { ScrollToTop } from '../../../components/scrolltotop';
import ProductCustomer from '../../../components/productcustomer';
const Rap638dv = () =>{
  const[opens,setOpens] = useState<string>('');
const removeDownload = () =>{
  setOpens('');
}
  const download =() =>{
    setOpens('/images/productDataSheet/Rapiscan-638DV-Datasheet.pdf');
  }

    return(<><NavBarHeader></NavBarHeader>
        <section className={styles.breadcrumbs}>
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                    <h2 className={styles.headingH2}>RAP 638DV</h2>
                    <Breadcrumb className="d-none d-sm-block">
                        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="#">Products</Breadcrumb.Item>
                        <Breadcrumb.Item active>baggage and parcel inspection</Breadcrumb.Item>
                        <Breadcrumb.Item active>RAP 638DV</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

            </div>
        </section>
        <section id="about" className="about">
      <div className="container aos-init aos-animate" data-aos="fade-up">

   

        <div className="row my-4">
          <div className="col-lg-6 text-center">
           
          <ProductList products={['/images/products/Rapiscan-638DV.jpg']}></ProductList>
          <br/> <a href="#" onClick={download} style={{"margin":"0 auto"}} className="btn btn-primary btn-lg"><FontAwesomeIcon icon={faDownload}  /> Data Sheet</a>
            </div> 
          <div className="col-lg-6 pt-4 pt-lg-0 content">
            <p className={styles.productdescr}>Dual view, low conveyor X-ray system for screening ULD type freight and ISO standard pallets.</p>
            <p className={styles.productdescr}>The 638DV is an advanced dual-view X-ray system for screening ULD, ISO standard, lower deck and large cargo pallet type freight. With a large and spacious tunnel size of 1,837 mm x 1,800 mm and explosives and narcotics detection alert feature, the 638DV is widely used for air cargo screening and customs applications where throughput is most important.</p>

            <p className={styles.productdescr}>The 638DV has been approved and included on the U.S. TSA Air Cargo Screening Technology List (ACSTL) and the Transport Canada (TC) Air Cargo Security Program (ACS).</p>
            
            <div className="row">
              <div className="col-lg-12">
              <ul className={`${styles.productslist} list-group list-group-flush`}>
    <li className="list-group-item"><FontAwesomeIcon icon={faCheckDouble} className="text-primary"  /> <span>EXPLOSIVES AND NARCOTICS DETECTION ALERT</span></li>
    <li className="list-group-item"><FontAwesomeIcon icon={faCheckDouble}  className="text-primary" /><span> MATRIXING, NETWORKING AND REMOTE VIEWING</span></li>
    <li className="list-group-item"><FontAwesomeIcon icon={faCheckDouble}  className="text-primary" /><span> MEETS ALL EUROPEAN COMMISSION AVIATION SCREENING REQUIREMENTS</span></li>
    <li className="list-group-item"><FontAwesomeIcon icon={faCheckDouble}  className="text-primary" /><span> HEAVY DUTY ROLLERBED</span></li>
    <li className="list-group-item"><FontAwesomeIcon icon={faCheckDouble}  className="text-primary" /><span> REGULATORY APPROVED ALARM FOR HIGH DENSITY OBJECTS</span></li>
    <li className="list-group-item"><FontAwesomeIcon icon={faCheckDouble}  className="text-primary" /><span> E.U. AVIATION COMPLAINT AND APPROVED BY U.S. TSA AND TRANSPORT CANADA</span></li></ul>    
 
              </div>
    
            </div>
   
          </div>
        </div>

      </div>
    </section>
    <section>
        <div className="container">
            
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
              <h3 className={styles.aboutH3}>DETECTION OF EXPLOSIVES AND NARCOTICS ALERT</h3>
              <p  className={styles.listPara}>Target and NARCScan are designed to assist operators in the detection of a range of explosives and narcotics respectively in real time during the scanning process by marking a potential threat on the X-ray image. Rapiscan Systems detection algorithms are based on regulatory material analysis techniques.</p>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
              <h3 className={styles.aboutH3}>EASE OF USE PROVIDING HIGHEST THROUGHPUT</h3>  
              <p  className={styles.listPara}>With over 13 image processing tools and detection alert algorithm functions, the feature rich software allows the operator to more easily and accurately search for contraband.</p>
                </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
              <h3 className={styles.aboutH3}>REGULATORY COMPLIANCE</h3>
              <p  className={styles.listPara}>638DV performance is in full compliance with the European Union regulations (EC) No. 300/2008, (EU) No. 2015/1998 and Commissions Decision C (2015)8005 for aviation security.</p>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
              <h3 className={styles.aboutH3}>DUAL VIEW ADVANCED TECHNOLOGY</h3>  
              <p  className={styles.listPara}>As mandated by US and EU regulators, the 638DV utilizes a dual-view technology which produces two simultaneous images (vertical and horizontal views) of the scanned object. It provides a more complete image, thereby reducing the need for repositioning and rescanning and enabling rapid, accurate and comprehensive threat detection.</p>
                </div>
              <div className="col-lg-12 col-md-6 col-12">
              <h3 className={styles.aboutH3}>DARC ALARM</h3>
              <p  className={styles.listPara}>Rapiscan Systems proprietary detection algorithm that detects areas of high density while screening air cargo. Rapiscan Systems collaborated closely with the U.K. Department for Transport to create DARC alarm and jointly executed the necessary test trials to verify the effectiveness of the DARC Alarm algorithm.</p>
              </div>
              </div>
              <section id="about" className="about my-3">
            <div className="section-title1 my-5"><h2>SPECIFICATION</h2>
            <div className="row my-5">
              <div className="col-lg-3">
              <h5>TUNNEL SIZE</h5>
              <p  className={styles.listPara1}>1,837 x 1,800 mm<br/> (72.3 x 70.9 in)</p>
              </div>
              <div className="col-lg-3">
              <h5>LENGTH</h5>
              <p  className={styles.listPara1}>10,620 mm (418.1 in.)</p>
              </div>
              <div className="col-lg-3">
              <h5>WIDTH</h5>
              <p  className={styles.listPara1}>3,116 mm (122.7 in.)</p>
              </div>
              <div className="col-lg-3">
              <h5>HEIGHT</h5>
              <p  className={styles.listPara1}>3,481 mm (137.0 in.)</p>
              </div>
            </div>
            </div>

            </section>
              </div>
    </section>
    <footer id={styles1.footer}>
        <div className={styles1.footerTop}>
          <div className="container">
            <div className="row">
              <FooterWeb></FooterWeb>
              <div className={styles1.container}>
                <div className={styles1.copyright}>
                  © 2021 <strong><span>ECIL Rapiscan Limited.</span></strong> All Rights Reserved
                </div>

              </div>
            </div>
          </div>
        </div>
      </footer>
      {opens && <ProductCustomer removeDownload={removeDownload} shows={opens} product="RAP 638DV"></ProductCustomer>}  
      <ScrollToTop></ScrollToTop>
        </>)
};

export default Rap638dv;