import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBarHeader } from '../../components/navbar';
import styles from './management.module.css';
import { Accordion, Breadcrumb } from 'react-bootstrap';
import AOS from 'aos';
import "aos/dist/aos.css";
import { FooterWeb } from '../../components/footer';
import styles1 from '../../components/footer/footer.module.css';

import { ScrollToTop } from '../../components/scrolltotop';
 const Management = () =>{
    useEffect(()=>{
        AOS.init({
          duration : 500,

      once: true
        });
      },[])
    return (<>
       <NavBarHeader></NavBarHeader>
        <section className={styles.breadcrumbs}>
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                    <h2 className={styles.headingH2}>Management</h2>
                    <Breadcrumb>
                        <Breadcrumb.Item href="#">Company</Breadcrumb.Item>
                        <Breadcrumb.Item active>Management</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

            </div>
        </section>
        <div className="container my-4"  data-aos="zoom-in">
        <Accordion defaultActiveKey="0">
  <Accordion.Item eventKey="0">
    <Accordion.Header>  <span className={`${styles.pHead} text-start  ${styles.textInfo}`}>Mr. Deepak Chopra</span>
          <p  className={`text-start  ${styles.pHeadS}`}>Chairman and CEO</p></Accordion.Header>
    <Accordion.Body>
    <div className="row ">
      <div className="col-lg-10 col-12">
      <p className={`text-start  ${styles.pDescr}`}>Mr. Deepak Chopra is one of the founder of the Company, Mr. Chopra has served as the Director of the Company, since its Inception i.e. since from the year 1995. Mr. Chopra is also the founder of OSI Systems, Inc. USA and has served as President, CEO and as a Director since the Company&apos;s inception in May 1987. Mr. Chopra holds a Bachelor of Science in Electronics from Punjab Engineering College and a Master of Science degree in Semiconductor Electronics from the University of Massachusetts, Amherst.</p>
      </div>
    <div className="col-lg-2 col-12">
        <img className="img-thumbnail rounded float-end" src="/images/management/chopra_photograph.png" alt=""/>
      </div>
      </div>
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="1">
    <Accordion.Header><span className={`${styles.pHead} text-start  ${styles.textInfo}`}>Mr. Ajay Mehra</span>
          <p  className={`text-start  ${styles.pHeadS}`}>Executive V.P., OSI Systems and President, Rapiscan Systems</p></Accordion.Header>
    <Accordion.Body>
    <div className="row ">
      <div className="col-lg-10 col-12">
      <p className={`text-start  ${styles.pDescr}`}>Mr. Ajay Mehra served as a Director of the company since its inception. Mr. Mehra is the EVP and Director of OSI Systems, Inc. USA and served the Company in various capacities since, 1989. Mr. Mehra holds a Bachelor of Arts degree from the school of business of the University of Massachusetts, Amherst and MBA degree from Pepperdine University.</p>
      </div>
    <div className="col-lg-2 col-12">
        <img className="img-thumbnail rounded float-end" src="/images/management/Ajay-mehra.jpg" alt=""/>
      </div>
      </div>
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="2">
    <Accordion.Header><span className={`${styles.pHead} text-start  ${styles.textInfo}`}>Mr. Mohinder Chopra</span>
          <p  className={`text-start  ${styles.pHeadS}`}>Director</p></Accordion.Header>
    <Accordion.Body>
    <div className="row ">
      <div className="col-lg-10 col-12">
      <p className={`text-start  ${styles.pDescr}`}>Mr. Mohinder Chopra holds a Bachelor of Electrical Engineering from IIT Delhi and MBA from University of Massachusetts, Amhert USA. Mr. Chopra served as alternate director of the company since many years and presently he is the Director of our company i.e., from 28.12.2015 and also holds the position of directorship In OSI Systems Private Limited since 01.11.2005</p>
      </div>
    <div className="col-lg-2 col-12">
        <img className="img-thumbnail rounded float-end" src="/images/management/mohinder_chopr_photo.png" alt=""/>
      </div>
      </div>
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="3">
    <Accordion.Header><span className={`${styles.pHead} text-start  ${styles.textInfo}`}>Mr. Vinod Kapoor</span>
          <p  className={`text-start  ${styles.pHeadS}`}>Wholetime Director, ECIL-Rapiscan Limited</p></Accordion.Header>
    <Accordion.Body>
    <div className="row ">
      <div className="col-lg-10 col-12">
      <p className={`text-start  ${styles.pDescr}`}>Mr. Vinod Kapoor joined ECIL-Rapiscan Limited in the year 1997 and presently serving as the Whole-time Director of the company. Prior to ERL, he has served as an Accounts Officer with Indian Immunological Limited, a unit of National Diary Development Board. Mr. Kapoor holds a Bachelor of Law degree and a Master degree in Business Administration.</p>
      </div>
    <div className="col-lg-2 col-12">
        <img className="img-thumbnail rounded float-end" src="/images/management/Vinod_kapoor.jpeg" alt=""/>
      </div>
      </div>
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="4">
    <Accordion.Header><span className={`${styles.pHead} text-start  ${styles.textInfo}`}>Dr. Anesh Kumar Sharma</span>
          <p  className={`text-start  ${styles.pHeadS}`}>Director (Technical), Electronics Corporation of India Limited</p></Accordion.Header>
    <Accordion.Body>
    <div className="row ">
      <div className="col-lg-10 col-12">
      <p className={`text-start  ${styles.pDescr}`}>Dr. Anesh Kumar Sharma has been nominated by ECIL as nominee director on board. Before joining ECIL, he was working as Scientist ‘G’ and Project Director, NASM – SR at RCI, DRDO, Hyderabad. Recently, he has successfully carried out a Naval Antiship Missile trial from ITR, Chandipur along with Navy team. Prior to NASM-SR, he was working as Associate Project Director of MRSAM(IAF) which is a joint development program of DRDO & IAI, Israel. He has specific specialization in RF & Microwave Sub-Systems & Monolithic Microwave Integrated Circuits (MMICs).</p>
      <p className={`text-start  ${styles.pDescr}`}>Prior to DRDO, he has worked at Semi Conductor Complex Ltd., (SCL) Chandigarh in semiconductor field and contributed in development of MMICs at GAETEC Project jointly by Semiconductor Complex Ltd. & DRDO.</p>
      <p className={`text-start  ${styles.pDescr}`}>Dr. Anesh Kumar Sharma is a Post Graduate in Engineering i.e., ME (ECE) from Osmania University and Ph.D in Electronics Engineering from IIT, Hyderabad.</p>
      </div>
    <div className="col-lg-2 col-12">
        <img className="img-thumbnail rounded float-end" src="/images/management/Anesh_Kumar_Sharma.png" alt=""/>
      </div>
      </div>
    </Accordion.Body>
  </Accordion.Item>
    <Accordion.Item eventKey="5">
    <Accordion.Header><span className={`${styles.pHead} text-start  ${styles.textInfo}`}>Mr. Anurag Kumar</span>
          <p  className={`text-start  ${styles.pHeadS}`}>Chairman and Managing Director, Electronics Corporation of India Limited</p></Accordion.Header>
    <Accordion.Body>
    <div className="row ">
      <div className="col-lg-10 col-12">
      <p className={`text-start  ${styles.pDescr}`}>Mr. Anurag Kumar has been nominated by ECIL as an nominee Director on board. He has rich professional experience of Manufacturing, Product Development, Project Executions and Business Operations,  in the field of Strategic Electronics, Telecom, Home Land Security, Renewable Energy and Steel sectors. Before Joining ECIL he has worked in Bharat Electronics Ltd and Reliance Industries Ltd.</p>
      <p className={`text-start  ${styles.pDescr}`}>Mr. Anurag Kumar is Graduate in Electrical & Electronics Engineering from NIT Jamshedpur.</p>
      </div>
    <div className="col-lg-2 col-12">
        <img className="img-thumbnail rounded float-end" src="/images/management/Anurag_Kumar.jpg" alt=""/>
      </div>
      </div>
    </Accordion.Body>
  </Accordion.Item>
  
  <Accordion.Item eventKey="6">
    <Accordion.Header><span className={`${styles.pHead} text-start  ${styles.textInfo}`}>Mr. Rajendra Kumar Parakh</span>
          <p  className={`text-start  ${styles.pHeadS}`}>Director (Finance), Electronics Corporation of India Limited</p></Accordion.Header>
    <Accordion.Body>
    <div className="row ">
      <div className="col-lg-10 col-12">
      <p className={`text-start  ${styles.pDescr}`}>Mr. Rajendra Kumar Parakh has assumed the charge of Nominee Director  of ECIL w.e.f. 19.09.2024.</p>
      <p className={`text-start  ${styles.pDescr}`}>He has been nominated on board of ECIL-Rapiscan Limited by ECIL.</p>
      <p className={`text-start  ${styles.pDescr}`}>He is Chartered Accountant, Company Secretary, Cost Accountant (ICWA) and DISA qualified.</p>
      <p className={`text-start  ${styles.pDescr}`}>He has more than 30 years experience in large Industrial Organisations in the area of Corporate Accounts, Fund raising through Debt & Equity, Budgeting, Taxation, Contract Management, Insurance and Risk Management. He managed several projects including IPO, GST rollout, IndAS implementation, De-notification of SEZ & SAP System set-up.</p>
      <p className={`text-start  ${styles.pDescr}`}>Prior to joining ECIL, Mr. Rajendra Kumar Parakh worked in ONGC Petro-additions Ltd and Gujarat State Petroleum Corporation Ltd.</p>
      </div>
    <div className="col-lg-2 col-12">
        <img className="img-thumbnail rounded float-end" src="/images/management/Rajendra_Kumar_Parakh.jpg" alt=""/>
      </div>
      </div>
    </Accordion.Body>
  </Accordion.Item>
</Accordion>

  {/* <div className={styles.card}>
    <div className="row ">
      <div className="col-10">
        <div className={styles["card-block"]}>

          <p className={`${styles.pHead} text-start fs-4 ${styles.textInfo}`}>Mr. Deepak Chopra</p>
          <p  className={`text-start  ${styles.pHeadS}`}>Chairman and CEO</p>
          <p className={`text-start  ${styles.pDescr}`}>Mr. Deepak Chopra is one of the founder of the Company, Mr. Chopra has served as the Director of the Company, since its Inception i.e. since from the year 1995.</p>
        </div>
      </div>

      <div className="col-lg-2">
        <img className="img-thumbnail rounded float-end  mx-lg-4 mt-lg-4 mx-0  mt-0" src="/images/management/chopra_photograph.png" alt=""/>
      </div>
    </div>
    <p   className={`text-start  ${styles.pDescr}   mx-3 mt-3`}>Mr. Chopra is also the founder of OSI Systems, Inc. USA and has served as President, CEO and as a Director since the Company&apos;s inception in May 1987. Mr. Chopra holds a Bachelor of Science in Electronics from Punjab Engineering College and a Master of Science degree in Semiconductor Electronics from the University of Massachusetts, Amherst.</p>
  </div> */}
  
    </div>
    {/* <div className="container"  data-aos="zoom-in">
  <div className={styles.card}>
    <div className="row ">
      <div className="col-10">
        <div className={styles["card-block"]}>

          <p className={`${styles.pHead} text-start fs-4  ${styles.textInfo}`}>Mr. Ajay Mehra</p>
          <p  className={`text-start  ${styles.pHeadS}`}>Executive V.P., OSI Systems and President, Rapiscan Systems</p>
          <p className={`text-start  ${styles.pDescr}`}>Mr. Ajay Mehra served as a Director of the company since its inception. Mr. Mehra is the EVP and Director of OSI Systems, Inc. USA and served the Company in various capacities since, 1989.</p>
        </div>
      </div>

      <div className="col-lg-2">
        <img className="img-thumbnail rounded float-end mx-lg-4 mt-lg-4 mx-0  mt-0" src="/images/management/Ajay-mehra.jpg" alt=""/>
      </div>
    </div>
    <p  className={`text-start  ${styles.pDescr}   mx-3 mt-3`}>Mr. Mehra holds a Bachelor of Arts degree from the school of business of the University of Massachusetts, Amherst and MBA degree from Pepperdine University.</p>
  </div>
  
    </div> */}
    {/* <div className="container"  data-aos="zoom-in">
  <div className={styles.card}>
    <div className="row ">
      <div className="col-10">
        <div className={styles["card-block"]}>

          <p className={`${styles.pHead} text-start fs-4 ${styles.textInfo}`}>Rear Admiral Sanjay Chaubey (Retd.)</p>
          <p  className={`text-start  ${styles.pHeadS}`}>Chairman & Managing Director, Electronics Corporation of India Limited</p>
          <p className={`text-start  ${styles.pDescr}`}>Rear Admiral Sanjay Chaubey (Retd.) has taken over as Chairman and Managing Director of the Electronics Corporation of India Ltd (ECIL) on 1st July, 2018.</p>
        </div>
      </div>

      <div className="col-lg-2">
        <img className="img-thumbnail rounded float-end  mx-lg-4 mt-lg-4 mx-0  mt-0" src="/images/management/Anesh_Kumar_Sharma.png" alt=""/>
      </div>
    </div>
    <p  className={`text-start  ${styles.pDescr} mx-3 mt-3`}>Mr. Chaubey was holding the position of Director (Technical) prior to this appointment and he is a graduate in Electrical Engineering from the National Institute of Technology, Rourkela and a post graduate in Communication Engineering from Indian Institute of Science, Bengaluru.</p>
  </div>
  
    </div> */}

    {/* <div className="container"  data-aos="zoom-in">
  <div className={styles.card}>
    <div className="row ">
      <div className="col-lg-10">
        <div className={styles["card-block"]}>

          <p className={`${styles.pHead} text-start fs-4 ${styles.textInfo}`}>Mr. Mohinder Chopra</p>
          <p  className={`text-start  ${styles.pHeadS}`}>Director</p>
          <p className={`text-start  ${styles.pDescr}`}>Mr. Mohinder Chopra holds a Bachelor of Electrical Engineering from IIT Delhi and MBA from University of Massachusetts, Amhert USA.</p>
        </div>
      </div>

      <div className="col-lg-2">
        <img className="img-thumbnail rounded float-end  mx-lg-4 mt-lg-4 mx-0  mt-0" src="/images/management/mohinder_chopr_photo.png" alt=""/>
      </div>
    </div>
    <p  className={`text-start mx-3 mt-2 ${styles.pDescr}`}>Mr. Chopra served as alternate director of the company since many years and presently he is the Director of our company i.e., from 28.12.2015 and also holds the position of directorship In OSI Systems Private Limited since 01.11.2005</p>
  </div>
  
    </div> */}
    {/* <div className="container mb-4" data-aos="zoom-in">
  <div className={styles.card}>
    <div className="row ">
      <div className="col-lg-10">
        <div className={styles["card-block"]}>

          <p className={`${styles.pHead} text-start fs-4 ${styles.textInfo}`}>Mr. Vinod Kapoor</p>
          <p  className={`text-start  ${styles.pHeadS}`}>Executive Vice President (Finance), ECIL-Rapiscan Limited</p>
          <p className={`text-start  ${styles.pDescr}`}>Mr. Vinod Kapoor joined ECIL-Rapiscan Limited in the year 1997 and presently serving as the Whole-time Director of the company.</p>
        </div>
      </div>

      <div className="col-lg-2">
        <img className="img-thumbnail rounded float-end  mx-lg-4 mt-lg-4 mx-0  mt-0" src="/images/management/Vinod_kapoor.jpeg" alt=""/>
      </div>
    </div>
    <p  className={`text-start mx-3 mt-2 ${styles.pDescr}`}>Prior to ERL, he has served as an Accounts Officer with Indian Immunological Limited, a unit of National Diary Development Board. Mr. Kapoor holds a Bachelor of Law degree and a Master degree in Business Administration.</p>
  </div>
  
    </div> */}
    <footer id={styles1.footer}>
        <div className={styles1.footerTop}>
          <div className="container">
            <div className="row">
              <FooterWeb></FooterWeb>
              <div className={styles1.container}>
                <div className={styles1.copyright}>
                  © 2021 <strong><span>ECIL Rapiscan Limited.</span></strong> All Rights Reserved
                </div>

              </div>
            </div>
          </div>
        </div>
      </footer>

      <ScrollToTop></ScrollToTop>
    </>);
}

export default Management;