import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { NavBarHeader } from '../../components/navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './safety.module.css';
import styles1 from '../../components/footer/footer.module.css';
import { FooterWeb } from '../../components/footer';
import { ScrollToTop } from '../../components/scrolltotop';
const SafetyAudits = () =>{
    return(<React.Fragment>
      <NavBarHeader></NavBarHeader>
        <section className={styles.breadcrumbs}>
        <div className="container">
 
 <div className="d-flex justify-content-between align-items-center">
     <h2 className={styles.headingH2}>Safety Audits</h2>
     <Breadcrumb>
         <Breadcrumb.Item href="#">Company</Breadcrumb.Item>
         <Breadcrumb.Item active>Safety Audits</Breadcrumb.Item>
     </Breadcrumb>
 </div>

</div>

        </section>
        <section >
            <div className="container  mb-4">
                <div className="row">
                    <div className="col-lg-12 pb-5">
                    <h4 className={styles.aboutH3}>Safety Audits</h4>
                        {/* <p className={styles.aboutp}><b>ECIL Rapiscan Ltd,</b> incorporated in the year 1995(and is a Joint Venture Company formed jointly by OSI Systems, Inc., USA and Electronics Corporation of India Limited, a wholly-owned Government of India Enterprise)</p> */}
                <p className={styles.aboutp}>Safety of the operating personnel is essential, and we provide safety audit services that our trained and experienced service professionals conduct.</p>
                <div></div>
                <h4 className={styles.aboutH3}>Atomic Energy Regulatory Board (AERB) Certification -Govt. of India</h4>
                <p className={styles.aboutp}><div className="row"><div className="col-lg-1 col-3"><img src="/images/AERB logo.png" alt="..." className="img-thumbnail mt-2"/></div><div className="col-lg-11 col-9">The mission of AERB is to ensure that the use of ionising radiation and nuclear energy in India does not cause unacceptable impact on workers, members of the public and to the environment. All products meet all the applicable standards laid down by the AERB and have been certified as such by AERB.</div></div></p>
                 <h4 className={styles.aboutH3}>ISO Certification</h4>
                <p className={styles.aboutp}>ECIL Rapiscan Limited has been certificated as ISO 9001:2015 for Marketing, Installation and Maintenance of x-ray baggage, cargo inspection systems, door frame metal detectors, hand held metal detectors &amp; marketing of x-ray generators and the quality of the products can therefore be guaranteed.</p>
                <h4 className={styles.aboutH3}>Food and FILM Safety Certification</h4>
                <p className={styles.aboutp}>Our products are Food safe and certified by Board of Radiation and Isotope Technology (BRIT) and FILM safety certified and confirms to ASA/ISO - 1600/33 DIN and ASA/ISO 800</p>
                <h4 className={styles.aboutH3}>Product Certification</h4>
                <p className={styles.aboutp}>Our products also hold the CE, TSA, CAAC, IEC certificates which stands for European Conformity. The letters confirm that the product meets the requirements of all relevant European Directives. The CE marking also indicates to governments and private parties that the product can be legally sold within the European Union.</p>
  </div></div>
            </div>
        </section>
        <br/><br/><br/><br/><br/>
        <footer id={styles1.footer}>
        <div className={styles1.footerTop}>
          <div className="container">
            <div className="row">
              <FooterWeb></FooterWeb>
              <div className={styles1.container}>
                <div className={styles1.copyright}>
                  © 2021 <strong><span>ECIL Rapiscan Limited.</span></strong> All Rights Reserved
                </div>

              </div>
            </div>
          </div>
        </div>
      </footer>
      <ScrollToTop></ScrollToTop>
    </React.Fragment>);
}

export default SafetyAudits;