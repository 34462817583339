import { Button, Form, Input, PageHeader, Select, Space, Table } from 'antd';
import { useHistory,useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import DashBoardL from '../../../components/dashboardL';
import { openNotificationWithIcon } from '../../../common';
import { config } from '../../../constant';
import TextArea from 'antd/lib/input/TextArea';


const ContactsLoc = () => {
    const router = useHistory();
    const [dataSource, setDataSource] = useState([]);
    const [regiondataSource, setregiondataSource] = useState([]);
    const [token,setToken] = useState<string>('');
    const {id}:any = useParams();
    const [location, setFetchLoc]= useState<Array<any>>([]);
    const [form] = Form.useForm();
    const {Option} = Select;
    const FetchLocation = async (body: any = '') => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
        };
        await fetch(config().API_URL + "/GetLocations", requestOptions)
            .then(response => response.json())
            .then(async (res: any) => {
                if (res.d) {
                    const reportResult = JSON.parse(res.d);
                    let reportArray: any = [];
                    await reportResult.forEach((value: any) => {
                        let reportObj: any = {};
                        reportObj["value"] = value["locName"];
                        reportObj["label"] = value["locName"];
                        reportArray.push(reportObj);
                    })
                    setFetchLoc(reportArray);
                }
            }).catch((err: any) => {
                console.log('Error--->', err);

            })
    }
    const editPositions = async(id:string) => {
        router.push('/dashboard/contacts/'+id);
    }
    const deletePositions = async (id: string) => {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

        
    
                var requestOptions:any = {
                    method: 'POST',
                    headers: myHeaders,
            body:JSON.stringify({
                id:id
            })
                };
            await fetch(config().API_URL+"/DeletePosition", requestOptions)
            .then(response=>response.json())
            .then(async(res: any) => {

            if(res.d){
            openNotificationWithIcon("Success", "Position Deleted Successfully!!!", "");
            // FetchData();
            }
        }).catch((err:any)=>{
        
            console.log('Error--->',err);
        })
    }
    const columns = [
        {
            title: 'Name',
            dataIndex: 'posName',
            key: 'posName',

        },
        {
            title: 'Region',
            dataIndex: 'regName',
            key: 'regName',

        },   {
            title: 'Location',
            dataIndex: 'conName',
            key: 'conName',

        },
        {
            title: 'Address',
            dataIndex: 'description',
            key: 'description',

        },
        {
            title: 'Action',
            key: 'action',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Button key="1" onClick={() => deletePositions(record.Id)} type="ghost" danger >

                        Delete
                    </Button>
                    <Button key="1" onClick={() => editPositions(record.Id)} type="primary" ghost >

Edit
</Button>
                </Space>
            ),
        },
    ];
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const FetchRegions = async (body:any='') => {
        debugger;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var url = '';
        if(body){
            url = config().API_URL+"/SelectRegion?id="+body;
        } else {
            url = config().API_URL+"/GetRegions"
        }
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
        };
        await fetch(url,requestOptions).then(response=>response.json()).then(async (res: any) => {
            const result = JSON.parse(res.d);
            if (res.d && !body) {
                setregiondataSource(result);
            } else if(res.d && body) {
                const respResult = JSON.parse(res.d);
                if(Array.isArray(respResult) && respResult.length>0){
                    form.setFieldsValue({
                        regName: respResult[0].regName,
                        posName: respResult[0].posName
                      });
                }
                
            }
        }).catch((err: any) => {
            console.log('Error--->', err);
     
        })
    }
    // const FetchData = async (body:any='') => {

    //     var myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/json");
    //     var requestOptions = {
    //         method: 'GET',
    //         headers: myHeaders,
    //     };
    //     await fetch(config().API_URL+"/GetPosition",requestOptions).then(response=>response.json()).then(async (res: any) => {
    //         const result = JSON.parse(res.d);
    //         if (res.d) {
    //             setDataSource(result);
    //         }
    //     }).catch((err: any) => {
    //         console.log('Error--->', err);
     
    //     })
    // }
    const FetchContacts = async (body:any='') => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var url = '';
        if(body){
 
            url = config().API_URL+"/GetContacts?id="+body;
        } else {
            url = config().API_URL+"/GetContact";
        }
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
        };
        await fetch(url,requestOptions).then(response=>response.json()).then(async (res: any) => {
            const result = JSON.parse(res.d);
            if (res.d && !body) {
                setDataSource(result);
            } else if(body){
                form.setFieldsValue({
                    posName: result[0].posName,
                    conName:result[0].conName,
                    regName: result[0].regName,
                    description: result[0].description
                  });
            }
        }).catch((err: any) => {
            console.log('Error--->', err);
     
        })
    }

    
    useEffect(()=>{
        const token = localStorage.getItem('token')|| '';
        setToken(token);
        // FetchData();
        FetchRegions();
        FetchLocation();
        FetchContacts(id);
      },[id])
      console.log('dataSource--->',dataSource);
    const onFinish = async (values: any) => {
        
        console.log('Success:', values);
        if(values.posName && values.conName && values.regName && values.description){
            var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");
            if(id){
                values.id = id;
            }
        
			var raw = JSON.stringify(values);
            var url = '';
            if(id){
                url =  config().API_URL+"/UpdateContact";
            } else {
                url =  config().API_URL+"/CreateContact";
            }
			var requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: raw
			};
			await fetch(url, requestOptions)
            .then((response)=>response.json()).then((res: any) => {
				if(res.d){
                    openNotificationWithIcon("Success", "Position Added Successfully!!!", "");
                    form.resetFields();
                    FetchContacts();
                    // FetchData();
				} else{
                    openNotificationWithIcon("Error", "Something Went Wrong", "");
				}
			}).catch((err:any)=>{
				console.log('Error--->',err);
     
			})
        } else {
            openNotificationWithIcon("Error", "Validation Failed", "Please Input all fields");
        }
    }
    return (<><DashBoardL>
        <><PageHeader
            className="site-page-header"

            title="Add  Contact"
            subTitle="View"
        /> <Form
        form={form}
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 6 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
                <Form.Item
                    label="Contact"
                    name="conName"
                    rules={[{ required: true, message: 'Enter Contact' }]}
                >
                    <Select  >
        {location.map((city:any) => (
          <Option key={city?.value}>{city?.label}</Option>
        ))}
      </Select>
                </Form.Item>
                <Form.Item
                    label="Position"
                    name="posName"
                    rules={[{ required: true, message: 'Enter Position' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Region"
                    name="regName"
                    rules={[{ required: true, message: 'Region Required' }]}
                >
                <Select  >
        {regiondataSource.map((city:any) => (
          <Option key={city?.regName}>{city?.regName}</Option>
        ))}
      </Select>
      </Form.Item>
                <Form.Item name="description" label="Description" rules={[{ required: true }]}>
          <TextArea rows={6} />
          </Form.Item>
                <Form.Item wrapperCol={{ offset:4 , span: 4}}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form> <Table dataSource={dataSource} columns={columns} /></></DashBoardL></>)
}

export default ContactsLoc;