import { Button, Form, Input, PageHeader, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import DashBoardL from '../../../components/dashboardL';
import { useHistory } from 'react-router-dom';
import { openNotificationWithIcon } from '../../../common';
import { config } from '../../../constant';

const Locations = () => {
    const [dataSource, setDataSource] = useState([]);
    const [token,setToken] = useState<string>('');
    const [form] = Form.useForm();
    const router = useHistory();
    const deleteDepartment = async (id: string) => {
        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
            var requestOptions:any = {
                method: 'POST',
                headers: myHeaders,
        body:JSON.stringify({
            id:id
        })
            };
        await fetch(config().API_URL+"/DeleteLocation", requestOptions)
        .then(response=>response.json())
        .then(async(res: any) => {
        const result = res.d;
        if(result){
        openNotificationWithIcon("Success", "Location Deleted Successfully!!!", "");
        FetchData();
        }
    }).catch((err:any)=>{
        console.log('Error--->',err);

    })
    }
    const columns = [
        {
            title: 'Name',
            dataIndex: 'locName',
            key: 'locName',

        },
        {
            title: 'Action',
            key: 'action',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Button key="1" onClick={() => deleteDepartment(record.Id)} type="ghost" danger >

                        Delete
                    </Button>
                </Space>
            ),
        },
    ];
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const FetchData = async (body:any='') => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
        };
        await fetch(config().API_URL+"/GetLocations",requestOptions)
        .then(response=>response.json())
        .then(async (res: any) => {
            const result = res.d;
            if (result) {
                setDataSource(JSON.parse(result));
            }
        }).catch((err: any) => {
            console.log('Error--->', err);
        })
    }
    useEffect(()=>{
        const token = localStorage.getItem('token')|| '';
        setToken(token);
        FetchData();
      },[])
    const onFinish = async (values: any) => {
        
        console.log('Success:', values);
        if(values.locName){
            var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");
			var raw = JSON.stringify({
				"locName": values.locName
			});
	
			var requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: raw
			};
			await fetch(config().API_URL+"/AddLocation", requestOptions)
            .then(response=>response.json())
            .then((res: any) => {
				 if(res.d){
					openNotificationWithIcon("Success", "Location Added Successfully!!!", "");
                    form.resetFields();
                    FetchData();
				
				} else {
                    openNotificationWithIcon("Error", "Authentication Failed", "Invalid Username or Password");
                }
			}).catch((err:any)=>{
				console.log('Error--->',err);
          
			})
        } else {
            openNotificationWithIcon("Error", "Validation Failed", "Please Input all fields");
        }
    }
    return (<><DashBoardL>
        <><PageHeader
            className="site-page-header"

            title="Location"
            subTitle="View"
        /> <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 4 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
                <Form.Item
                    label="Location"
                    name="locName"
                    rules={[{ required: true, message: 'Enter Location' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ offset:4 , span: 4}}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form> <Table dataSource={dataSource} columns={columns} /></></DashBoardL></>)
}

export default Locations;