import { Button, DatePicker,  Input, PageHeader, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import DashBoardL from '../../../../components/dashboardL';
import { useHistory, useParams } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { Form } from "antd"

import moment from 'moment';
import { openNotificationWithIcon } from '../../../../common';
import { config } from '../../../../constant';
const AddCSRReport = () =>{
    const router = useHistory();
    const [form] = Form.useForm();
    let { id }:any = useParams();
    const [fileupload,setfileUpload] = useState<any>();
    const [files,setFiles ]= useState<any>();
    const onFinish = async(values: any) => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      // "year": moment(values.year).format('YYYY'),
      let api = '';
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          "year": moment(values.year).format('YYYY'),
        })
      };
      if(id){
        api = config().API_URL+"/UpdateCSRYear";
        requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            "year": moment(values.year).format('YYYY'),
            id: id
          })
        };
      } else {
        api = config().API_URL+"/AddCSRYear"
      }
 

      if(values.year && fileupload) {
       await fetch(api, requestOptions).then(response => response.json()).then(async(resp: any) => {    
        if(resp.d){
      for (var i = 0; i < fileupload.length; i++) {  


          var formdata = new FormData();
    
          await formdata.append(fileupload[i].name, fileupload[i]);

          var requestOptions1 = {
            method: 'POST',
            body: formdata
          };

await fetch(config().UPLOAD_URL+"fileUpload.ashx", requestOptions1)
  .then(response => response.text())
  .then(async(result) => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = "";
    let url = "";
    if(id){
      raw = JSON.stringify({
                "files":result, "id":id
      });
      url = config().API_URL+"/UpdateCSR"
    } else {
      raw = JSON.stringify({
      "files":result,
       "id":resp.d
      });
      url = config().API_URL+"/AddCSR"
    }
 

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw
    };
    await fetch(url, requestOptions).then(response => response.json()).then((res: any) => {
     if(res.d){
        openNotificationWithIcon("Success", "Created Successfully!!!", "");
        router.push('/dashboard/csr')
      } else {
        openNotificationWithIcon("Error", "Authentication Failed", "Invalid Username or Password");
      }
      
    }).catch((err:any)=>{
      console.log('Error--->',err);
    })
  })
  .catch(error => console.log('error', error));

}
       }
})
        } else {
            openNotificationWithIcon("Error", "Validation Failed", "Please Input all fields");
        }
 
      };
    
      const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
      };
      const normFile = (e: any) => {
        console.log('Upload event:', e);
        if(e.file.response=="Token Expired"){
            localStorage.removeItem('token');
            router.push('/login')
        }
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
      };
      const getReport = (id:string)  =>{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
        };
        
        fetch(config().API_URL+"/GetCSRReport?id="+id, requestOptions)
          .then(response => response.json())
          .then(result => {
            if(result.d){
              const respResult = JSON.parse(result.d);
              if(respResult && Array.isArray(respResult) && respResult.length>0){
                const date = new Date();
                date.setFullYear(respResult[0].year)
                form.setFieldsValue({
                  year: moment(date)
                });
                setFiles(respResult);
              }
              
            }
            
          })
          .catch(error => console.log('error', error));
      }
      const download_files =(files:any) => {
        var a:any = document.createElement('a');
        a.href = config().UPLOAD_URL+"/DownloadFile.ashx?id="+ files;
        a.target = '_blank';
                 if (a.click) {
          a.click(); // The click method is supported by most browsers.
        }
      }
      useEffect(()=>{
        if(id){
          getReport(id);
        }
      },[]);
    
    return(<><DashBoardL>
        <>
        <PageHeader
            className="site-page-header"
            onBack={() => 	router.push('/dashboard/csr')}
            title="Corporate Social Responsibility"
            subTitle="Create"
        /> <Form  form = {form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}

        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
 
        <Form.Item
          label="Year"
          name="year"
          rules={[{ required: true, message: 'Please input year for Corporate Social Responsibility!' }]}
        >
          <DatePicker   picker="year" />
        </Form.Item> 
        <Form.Item
        name="upload"
        label="Upload"
       

      >
       <input type="file" multiple onChange={(event:any)=>{
         setfileUpload(event.target.files)
         }}/>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
      {Array.isArray(files) && files.length>0 && files.map((value:any)=>{
  return(<><Button key="1" type="default" onClick={()=>download_files(value.files)}>
  {value.files}
</Button><span>&nbsp;</span></>)
                  })}</Form.Item>

<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
  <Button type="primary" htmlType="submit">
    Submit
  </Button>
</Form.Item>
</Form></>
    </DashBoardL>;</>)
}


export default AddCSRReport;