import React from 'react';
import {Carousel} from 'react-bootstrap';
import Styles from './carousel.module.css';
export const BannerCarousel =() =>{
    return(<Carousel>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src="/images/mask-group-30-1000x.jpg"
      alt="First slide"
    />
    <Carousel.Caption>
      <h3  className="display-3   animate__animated animate__zoomInDown" >VISION INTO REALITY</h3>
      <p className={`${Styles.carouselp} animate__animated  animate__fadeInRightBig`}>WE ARE PIONEERS IN PROVIDING SECURITY PRODUCTS & SERVICES SINCE 1995 (25 YEARS+)<br/>AND WE ARE READY TO EMBRACE THE OPPORTUNITY GIVEN TO US<br/>TO PROVIDE THE BEST AND TO DELIVER IN TIME
</p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src="/images/mask-group-29-1000x.jpg"
      alt="Second slide"
    />

    <Carousel.Caption>
      <h3   className="display-3  animate__animated animate__zoomInDown">TO THE NEXT LEVEL</h3>
      <p  className={`${Styles.carouselp} animate__animated  animate__fadeInLeftBig`}>OUR SECURITY PRODUCTS WHICH ARE BACKED-UP WITH RAPISCAN TECHNOLOGY<br/>WHICH LEADS TO DELIVER THE BEST SOLUTIONS<br/>BASED ONA DEEP UNDERSTANDING OF YOUR BUSINESS

</p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src="/images/photo-1496247749665-49cf5b1022e9-1000x.jpg"
      alt="Third slide"
    />

    <Carousel.Caption>
      <h3   className="display-3   animate__animated animate__fadeInDownBig">AMBITION INTO ACTION</h3>
      <p  className={`${Styles.carouselp} animate__animated  animate__fadeInLeftBig`}>OUR AMBITION IS TO PROVIDE HIGH CLASS SECURITY PRODUCTS<br/>TO PROTECT THE LIFE OF INDIAN’S AGAINST ANY TERRORIST ACT,<br/>WE ARE NOT COMPLACENT AND ALWAYS FOCUS TO PROVIDE<br/>BEST AFTER-SALES SERVICES (24x7) WITH A CALL CENTER NUMBER 1800-108-7499<br/>FOR OUR CUSTOMERS TO REACH US ANY TIME ANY WHERE IN INDIA
</p>
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>)
}