import React, { useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { NavBarHeader } from '../../../components/navbar';
import styles from './918cx.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckDouble, faCircle, faDownload} from '@fortawesome/free-solid-svg-icons';
import { FooterWeb } from '../../../components/footer';
import styles1 from '../../../components/footer/footer.module.css';
import { ScrollToTop } from '../../../components/scrolltotop';
import ProductCustomer from '../../../components/productcustomer';
const R918cx = () =>{
  const[opens,setOpens] = useState<string>('');
const removeDownload = () =>{
  setOpens('');
}
  const download =() =>{
    setOpens('/images/productDataSheet/DSBP-0002-918CX-184302.pdf');
  }
    return(<><NavBarHeader></NavBarHeader>
        <section className={styles.breadcrumbs}>
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                    <h2 className={styles.headingH2}>918CX</h2>
                    <Breadcrumb  className="d-none d-sm-block">
                        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="#">Products</Breadcrumb.Item>
                        <Breadcrumb.Item active>baggage and parcel inspection</Breadcrumb.Item>
                        <Breadcrumb.Item active>918CX</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

            </div>
        </section>
        <section id="about" className="about">
      <div className="container aos-init aos-animate" data-aos="fade-up">

   

        <div className="row my-4">
          <div className="col-lg-6 text-center">
           
            <img src="/images/products/918CX.jpg" className="img-fluid pt-5" alt=""/><br/><br/>
            <a href="#" onClick={download} style={{"margin":"0 auto"}} className="btn btn-primary btn-lg"><FontAwesomeIcon icon={faDownload}  /> Data Sheet</a>
            </div>
          
          
          <div className="col-lg-6 pt-4 pt-lg-0 content">
            <p className={styles.productdescr}>The 918CX is a high performance, compact and versatile 540mm by 360mm tunnel X-Ray screening system with exceptional image quality and excellent threat detection alert capabilities.</p>
            <p className={styles.productdescr}>Orion 918CX offers best in class spatial resolution providing exceptional image quality but it&apos;s small size make it an ideal solution for schools, hotels, convention centers and event-based security environments.</p>
            <div className="row">
              <div className="col-lg-12">
              <ul className={`${styles.productslist} list-group list-group-flush`}>
    <li className="list-group-item"><FontAwesomeIcon icon={faCheckDouble} className="text-primary"  /> <span>INDUSTRY BEST SPATIAL RESOLUTION</span></li>
    <li className="list-group-item"><FontAwesomeIcon icon={faCheckDouble} className="text-primary"  /> <span>EXPLOSIVES & NARCOTICS DETECTION</span></li>
    <li className="list-group-item"><FontAwesomeIcon icon={faCheckDouble} className="text-primary"  /> <span>LOW OPERATING POWER CONSUMPTION</span></li>
    <li className="list-group-item"><FontAwesomeIcon icon={faCheckDouble} className="text-primary"  /> <span>MATRIXING, NETWORKING & REMOTE VIEWING</span></li>
    <li className="list-group-item"><FontAwesomeIcon icon={faCheckDouble} className="text-primary"  /> <span>HIGHER OPERATIONAL AVAILABILITY - SIGNIFICANT REDUCTION IN CUSTOMER DOWN TIME</span></li>
    <li className="list-group-item"><FontAwesomeIcon icon={faCheckDouble} className="text-primary"  /> <span>COMPLIANT WITH EUROPEAN COMMISSION AVIATION SECURITY REQUIREMENTS</span></li>
     </ul>    
 
              </div>
    
            </div>
   
          </div>
        </div>

      </div>
    </section>
    <section>
        <div className="container">
            <div className="row">
            <div className="offset-md-1 col-md-2 col-12">
                    <div className={`${styles.circleIcon}`}>
                
                        <FontAwesomeIcon icon={faCheck} className="text-center"  color="white" size="2x"  />
                        
                    </div>
                    <p className={`${styles.circleIconp} text-center mt-3`}>64-BIT<br/> WINDOWS<br/> 10 OPERATING<br/> SYSTEM</p>
                    </div>
                    <div className=" col-md-2 col-12">
                    <div className={`${styles.circleIcon} ${styles.circleIconp}`}>
                
                        <FontAwesomeIcon icon={faCheck} className="text-center"   color="white" size="2x"  />
                        
                    </div>
                    <p className={`${styles.circleIconp} text-center mt-3`}>REGULATORY<br/> BASED EXPLOSIVES<br/> DETECTION<br/> ALGORITHMS</p>
                    </div>
                    <div className=" col-md-2 col-12">
                    <div className={`${styles.circleIcon} ${styles.circleIconp}`}>
                
                        <FontAwesomeIcon icon={faCheck} className="text-center"   color="white" size="2x"  />
                        
                    </div>
                    <p className={`${styles.circleIconp} text-center mt-3`}>17 IMAGE<br/> PROCESSING<br/> FUNCTIONS</p>
                    </div>
                    <div className=" col-md-2 col-12">
                    <div className={`${styles.circleIcon} ${styles.circleIconp}`}>
                
                        <FontAwesomeIcon icon={faCheck} className="text-center"   color="white" size="2x"  />
                        
                    </div>
                    <p className={`${styles.circleIconp} text-center mt-3`}>SPECTRUM<br/> 4-COLOR<br/> IMAGING</p>
                    </div>
                    <div className=" col-md-2 col-12">
                    <div className={`${styles.circleIcon} ${styles.circleIconp}`}>
                
                        <FontAwesomeIcon icon={faCheck} className="text-center"   color="white" size="2x"  />
                        
                    </div>
                    <p className={`${styles.circleIconp} text-center mt-3`}>SINERGY LOW<br/> DENSITY IMAGE<br/> ENHANCEMENT</p>
                    </div>
                    </div>
                    <div className="row">
             
                    <div className="offset-md-4  col-md-2 col-12">

                    <div className={`${styles.circleIcon} ${styles.circleIconp}`}>
                
                        <FontAwesomeIcon icon={faCheck} className="text-center"   color="white" size="2x"  />
                        
                    </div>
                    <p className={`${styles.circleIconp} text-center mt-3`}>DUAL MODE<br/> Z-EFFECTIVE</p>
                    </div>
                    <div className=" col-md-2 col-12">
                    <div className={`${styles.circleIcon} ${styles.circleIconp}`}>
                
                        <FontAwesomeIcon icon={faCheck} className="text-center"   color="white" size="2x"  />
                        
                    </div>
                    <p className={`${styles.circleIconp} text-center mt-3`}>AUTOMATIC,<br/> ENHANCED IMAGE<br/> ARCHIVING</p>
                    </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
              <h3 className={styles.aboutH3}>SPECTRUM 4-COLOR IMAGING</h3>
              <p  className={styles.listPara}>918CX now can exhibit images in the Classic 4-color and the new proprietary Spectrum 4-color (SP4) option providing superior image, allowing improved security by quick and accurate identification of threats and increase in throughput.</p>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
              <h3 className={styles.aboutH3}>DUAL MODE AUTO ZEFF </h3>  
              <p className={styles.listPara}>This feature helps operators identify organics accurately and quickly either in Range Mode which highlights the areas based on selected Z-effective numbers of 7, 8 or 9 by the operator, or in Interactive Mode which gives the operator the option to display the Z-effective number in all the areas of the scanned item based on the value of the pixel. Orion family are the only systems in industry that can support both Range Mode and Interactive Mode.</p>
             </div>    
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
              <h3 className={styles.aboutH3}>EXPLOSIVES AND NARCOTICS DETECTION</h3>
              <p  className={styles.listPara}>
              Target and NARCScan are designed to detect a wide range of explosives and narcotics respectively in real time during the scanning process by marking a potential threat on the X-ray image. Rapiscan® Systems detection algorithms are based on regulatory approved material analysis techniques.</p>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
              <h3 className={styles.aboutH3}>EUROPEAN AVIATION COMPLIANCE</h3>  
              <p  className={styles.listPara}> 918CX performance is in full compliance with the mandatory regulations (EC) No. 300/2008, (EU) No. 185/2010 and commissions decision C(2010)774 for aviation security in the European Union.</p>
                </div>
            </div>
            <section id="about" className="about my-3">
            <div className="section-title1 my-5"><h2>SPECIFICATION</h2>
            <div className="row my-5">
              <div className="col-lg-3">
              <h5>TUNNEL SIZE</h5>
              <p  className={styles.listPara1}>540 x 360 mm (21.3 x 14.2 in)</p>
              </div>
              <div className="col-lg-3">
              <h5>LENGTH</h5>
              <p  className={styles.listPara1}>1,657 mm (65.2 in)</p>
              </div>
              <div className="col-lg-3">
              <h5>WIDTH</h5>
              <p  className={styles.listPara1}>794 mm (31.3 in)</p>
              </div>
              <div className="col-lg-3">
              <h5>HEIGHT</h5>
              <p  className={styles.listPara1}>1,356 mm (53.3 in)</p>
              </div>
            </div>
            </div>

            </section>
        </div>
    </section>
    <footer id={styles1.footer}>
        <div className={styles1.footerTop}>
          <div className="container">
            <div className="row">
              <FooterWeb></FooterWeb>
              <div className={styles1.container}>
                <div className={styles1.copyright}>
                  © 2021 <strong><span>ECIL Rapiscan Limited.</span></strong> All Rights Reserved
                </div>

              </div>
            </div>
          </div>
        </div>
      </footer>
      {opens && <ProductCustomer removeDownload={removeDownload} shows={opens} product="918CX"></ProductCustomer>}  
      <ScrollToTop></ScrollToTop>
        </>)
};

export default R918cx;
