import React, { useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { NavBarHeader } from '../../../components/navbar';
import styles from './rap636sv.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckDouble, faCircle, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FooterWeb } from '../../../components/footer';
import styles1 from '../../../components/footer/footer.module.css';
import ProductCustomer from '../../../components/productcustomer';
const Rap636sv = () =>{
  const[opens,setOpens] = useState<string>('');
const removeDownload = () =>{
  setOpens('');
}
  const download =() =>{
    setOpens('/images/productDataSheet/Rapiscan-MobileCheck-636SV-Datasheet.pdf');
  }

    return(<><NavBarHeader></NavBarHeader>
        <section className={styles.breadcrumbs}>
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                    <h2 className={styles.headingH2}>RAP 636SV</h2>
                    <Breadcrumb className="d-none d-sm-block">
                        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="#">Products</Breadcrumb.Item>
                        <Breadcrumb.Item active>RAP 636SV</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

            </div>
        </section>
        <section id="about" className="about">
      <div className="container aos-init aos-animate" data-aos="fade-up">

   

        <div className="row my-4">
          <div className="col-lg-4 text-center">
           
            <img src="/images/products/Rapiscan-MobileCheck-636SV.jpeg" className="img-fluid pt-5" alt=""/>
            <br/> <br/><br/> <a href="#" onClick={download} style={{"margin":"0 auto"}} className="btn btn-primary btn-lg"><FontAwesomeIcon icon={faDownload}  /> Data Sheet</a>
            </div>
          
          <div className="col-lg-8 pt-4 pt-lg-0 content">
            <p className={styles.productdescr}>The Rapiscan MobileCheck 636SV is a state of the art self-contained mobile X-ray screening unit with a large tunnel opening of 1,000mm (40in) by 1,000mm (40in).
            </p>
            <p  className={styles.productdescr}>
            With an innovative design the ability to rapidly deploy baggage and parcel inspection at any location with one complete unit is possible. The Rapiscan MobileCheck 636SV can be deployed and operated in three different modes, from either side of the van or in a pass through mode.
            </p>
            <p  className={styles.productdescr}>
            Like all systems in the 600 series family of X-ray scanning solutions, the Rapiscan MobileCheck 636SV X-ray screening system is built on a standardized platform that makes it easy to operate and maintain, and comes standard with features such as dual-energy detection, a streamlined, ergonomic design, and Rapiscan&apos;s feature-rich OS600 system software.
            </p> 
            <div className="row">
              <div className="col-lg-12">
              <ul className={`${styles.productslist} list-group list-group-flush`}>
    <li className="list-group-item"><FontAwesomeIcon icon={faCheckDouble} className="text-primary"  /> <span> Self-contained Mobile Screening</span></li>
    <li className="list-group-item"><FontAwesomeIcon icon={faCheckDouble}  className="text-primary" /><span>	Large Tunnel X-ray</span></li>
    <li className="list-group-item"><FontAwesomeIcon icon={faCheckDouble}  className="text-primary" /><span> Three Operational Modes</span></li>
    <li className="list-group-item"><FontAwesomeIcon icon={faCheckDouble}  className="text-primary" /><span> Rapid Deployments</span></li>
     </ul>    
 
              </div>
    
            </div>
   
          </div>
        </div>
        <section id="about" className="about my-3">
            <div className="section-title1 my-5"><h2>SPECIFICATION</h2>
            <div className="row my-5">
              <div className="col-lg-3">
              <h5>TUNNEL SIZE</h5>
              <p  className={styles.listPara1}>1,016 x 1,016 mm (40 x 40 in)</p>
              </div>
              <div className="col-lg-3">
              <h5>HEIGHT</h5>
              <p  className={styles.listPara1}>210 mm (8.3 in.)</p>
              </div>
              <div className="col-lg-3">
              <h5>Weight</h5>
              <p  className={styles.listPara1}> 896 Kg (1,975 lbs)</p>
              </div>
            </div>
            </div>

            </section>
      </div>
    </section>
    <footer id={styles1.footer}>
        <div className={styles1.footerTop}>
          <div className="container">
            <div className="row">
              <FooterWeb></FooterWeb>
              <div className={styles1.container}>
                <div className={styles1.copyright}>
                  © 2021 <strong><span>ECIL Rapiscan Limited.</span></strong> All Rights Reserved
                </div>

              </div>
            </div>
          </div>
        </div>
      </footer>
      {opens && <ProductCustomer removeDownload={removeDownload} shows={opens} product="RAP 636SV"></ProductCustomer>}  
        </>)
};

export default Rap636sv;