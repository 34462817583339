import React, { useEffect, useState } from 'react';
import { Accordion, Breadcrumb } from 'react-bootstrap';
import styles from './report.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBarHeader } from '../../components/navbar';
import { ScrollToTop } from '../../components/scrolltotop';
import { FooterWeb } from '../../components/footer';
import styles1 from '../../components/footer/footer.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Select } from 'antd';
import moment from 'moment';
import { config } from '../../constant';
const Report = () => {
    const [dataSource, setDataSource] = useState<Array<any>>([]);
    const [year,setYear] = useState<Array<any>>();
    const FetchData = async (body:any='') => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let url =''
        if(body){
            url = config().API_URL+"/Getannualreport?year="+body;
        } else {
            url = config().API_URL+"/Addannualreport";
        }


        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
        };
        await fetch(url, requestOptions).then(response=>response.json()).then(async (res: any) => {
            if (res.d) {
                const respResult= JSON.parse(res.d);
                const finalResult = respResult.sort((a:any,b:any)=>parseInt(b.year)-parseInt(a.year))
                setDataSource(finalResult);
            }
        }).catch((err: any) => {
            console.log('Error--->', err);
        })
    }
    useEffect(() => {
        FetchData();
        var date = new Date();
        var year = date.getFullYear();
        var option = ["All"];
        for (var i:any = year - 10; i <= year + 3; i++) {
            option.push(i);
          }
          setYear(option.sort((a:any,b:any)=>b-a));
    }, [])

    const handleChange = (value:any) =>{
        console.log(value.key); // { value: "lucy", key: "lucy", label: "Lucy (101)" }
        if(value.key==="All"){
            FetchData();
        } else {
            if(year && year[value.key])
            FetchData(year[value.key]|| '');
        }
        
      }
      
    console.log('reportResult--->', moment().format('YYYY'));
    return (<><NavBarHeader></NavBarHeader>
        <section className={styles.breadcrumbs}>
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                    <h2 className={styles.headingH2}>Annual Report</h2>
                    <Breadcrumb className="d-none d-sm-block">
                        <Breadcrumb.Item href="#">Company</Breadcrumb.Item>
                        <Breadcrumb.Item>Annual Report</Breadcrumb.Item>

                    </Breadcrumb>
                </div>

            </div>
        </section>

        <section>
            <div className="container  mb-4">
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className={styles.aboutH3}>Recent Annual Report </h3>&nbsp;&nbsp;
                        <Select
                            labelInValue
                            defaultValue={{"value":"All"}}
                            style={{ width: 120 }}
                            onChange={handleChange}
                        >
                            {year?.map((value: any, index: number) => <Select.Option value={value} key={index}>{value}</Select.Option>)}                          

                        </Select>
                        <Accordion defaultActiveKey="0">
                            {dataSource.map((value: any, index: number) => <Accordion.Item key={index} eventKey={index.toString()}>
                                <Accordion.Header>  <span className={`${styles.pHead} text-start  ${styles.textInfo}`}>{value.title + ' - ' + value.year}</span>
                                    {/* <p className={`text-start  ${styles.pHeadS}`}>Chairman and CEO</p> */}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className="row ">
                                        <div className="col-12">
                                            <table className="table table-striped ">
                                                <thead className={styles.theadStyle}>
                                                    <th>Title</th>
                                                    <th>Year</th>
                                                    <th>Download</th>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{value.title}</td>
                                                        <td>{value.year}</td>
                                                        <td><a type="button" href={config().UPLOAD_URL+"/DownloadFile.ashx?id="+ value.files} className="btn btn-outline-primary"><FontAwesomeIcon icon={faDownload} /> Download</a></td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>)}

                        </Accordion>
                    </div>
                </div>
            </div>
        </section>
        <footer id={styles1.footer}>
            <div className={styles1.footerTop}>
                <div className="container">
                    <div className="row">
                        <FooterWeb></FooterWeb>
                        <div className={styles1.container}>
                            <div className={styles1.copyright}>
                                © 2021 <strong><span>ECIL Rapiscan Limited.</span></strong> All Rights Reserved
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </footer>

        <ScrollToTop></ScrollToTop>
    </>)
};

export default Report;
