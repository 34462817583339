import { Button, DatePicker,  Input, PageHeader, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import DashBoardL from '../../../../components/dashboardL';
import { useHistory, useParams } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { Form } from "antd"

import moment from 'moment';
import { openNotificationWithIcon } from '../../../../common';
import { config } from '../../../../constant';
const AddAnnualReport = () =>{
    const router = useHistory();
    const [form] = Form.useForm();
    let { id }:any = useParams();
    const[initialValues, setInitialValue] = useState<Array<any>>([]);
    const [fileupload,setfileUpload] = useState<any>();
    const onFinish = async(values: any) => {
        console.log('Success:', values);
        if(values.title && fileupload && values.year) {
          debugger;
          if((Array.isArray(initialValues)&&initialValues.length>0 &&(initialValues.indexOf(moment(values.year).format('YYYY').toString())===-1 )) || id || initialValues.length===0){
          var formdata = new FormData();
          await formdata.append("file", fileupload);
          var requestOptions1 = {
            method: 'POST',
            body: formdata
          };

await fetch(config().UPLOAD_URL+"fileUpload.ashx", requestOptions1)
  .then(response => response.text())
  .then(async(result) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = "";
    let url = "";
    if(id){
      raw = JSON.stringify({
        "title": values.title ,
        "year": moment(values.year).format('YYYY'),
                "files":result, "id":id
      });
      url = config().API_URL+"/UpdateReport"
    } else {
      raw = JSON.stringify({
        "title": values.title ,
        "year": moment(values.year).format('YYYY'),
                "files":result
      });
      url = config().API_URL+"/AddReport"
    }
 

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw
    };
    await fetch(url, requestOptions).then(response => response.json()).then((res: any) => {
     if(res.d){
        openNotificationWithIcon("Success", "Reports Added Successfully!!!", "");
        router.push('/dashboard/annualReport')
      } else {
        openNotificationWithIcon("Error", "Authentication Failed", "Invalid Username or Password");
      }
    }).catch((err:any)=>{
      console.log('Error--->',err);
    })
  })
  .catch(error => console.log('error', error));
}
else {
  openNotificationWithIcon("Error", "Please Select Different Year", "Annual Report already uploaded");
}
        } else {
            openNotificationWithIcon("Error", "Validation Failed", "Please Input all fields");
        }
      };
    
      const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
      };
      const normFile = (e: any) => {
        console.log('Upload event:', e);
        if(e.file.response=="Token Expired"){
            localStorage.removeItem('token');
            router.push('/login')
        }
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
      };
      const getReport = (id:string)  =>{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
        };
        
        fetch(config().API_URL+"/GetReport?id="+id, requestOptions)
          .then(response => response.json())
          .then(result => {
            if(result.d){
              const respResult = JSON.parse(result.d);
              if(respResult && Array.isArray(respResult) && respResult.length>0){
                const date = new Date();
                date.setFullYear(respResult[0].year)
                form.setFieldsValue({
                  title: respResult[0].title,
                  year: moment(date)
                });
              }
              
            }
            
          })
          .catch(error => console.log('error', error));
      }
      const FetchData = async() =>{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
  
        var requestOptions = {
          method: 'GET',
          headers: myHeaders
        };
        await fetch(config().API_URL+"/Addannualreport", requestOptions)
        .then(response=>response.json()).then(async(res: any) => {
  
         if(res.d){
          const respResult= JSON.parse(res.d);

            const yearData = respResult.map((value:any)=>{
              return value.year
            });
            setInitialValue(yearData);

         
         }
       }).catch((err:any)=>{
         console.log('Error--->',err);
       })
      }
      useEffect(()=>{
        if(id){
          getReport(id);
        } else {
          FetchData()
        }
       
      },[])
      console.log("initialValues --->",initialValues);
    return(<><DashBoardL>
        <>
        <PageHeader
            className="site-page-header"
            onBack={() => 	router.push('/dashboard/annualReport')}
            title="Annual Report"
            subTitle="Create"
        /> <Form  form = {form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}

        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: 'Please input title for Annual Report!' }]}
        >
          <Input />
        </Form.Item> 
        <Form.Item
          label="Year"
          name="year"
          rules={[{ required: true, message: 'Please input year for Annual Report!' }]}
        >
          <DatePicker  disabled={id?true:false}  picker="year" />
        </Form.Item> 
        <Form.Item
        name="upload"
        label="Upload"
       

      >
       <input type="file" onChange={(event:any)=>{
         setfileUpload(event.target.files[0])
         }}/>
      </Form.Item>
 

<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
  <Button type="primary" htmlType="submit">
    Submit
  </Button>
</Form.Item>
</Form></>
    </DashBoardL>;</>)
}


export default AddAnnualReport;