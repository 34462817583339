import React, { useEffect, useState } from 'react';
import { Accordion, Breadcrumb, ListGroup, Modal, Button as ButtonR } from 'react-bootstrap';
import { NavBarHeader } from '../../components/navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { UploadOutlined } from '@ant-design/icons';
import { FooterWeb } from '../../components/footer';
import styles from './opening.module.css';
import styles1 from '../../components/footer/footer.module.css';
import { ScrollToTop } from '../../components/scrolltotop';
import { SearchOutlined } from '@ant-design/icons';
import { Select, Tooltip, Button, Form, Input, Upload } from 'antd';
import { openNotificationWithIcon } from '../../common';
import { config } from '../../constant';

const Opening = () => {
  const { Option } = Select;
  const [dataSource, setDataSource] = useState<Array<any>>([]);
  const [fetchPos, setFetchPos] = useState<Array<any>>();
  const [fetchLoc, setFetchLoc] = useState<Array<any>>();
  const [postion, setPosition] = useState<string>();
  const [locations, setLocation] = useState<Array<any>>();
  const [fileupload,setfileUpload] = useState<any>();
  const [job, setJob] = useState<any>();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (value: any) => {
    setShow(true);
    setJob(value);
  };
  const FetchPosition = async (body: any = '') => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };
    await fetch(config().API_URL + "/GetPosition", requestOptions)
    .then(response => response.json())
    .then(async (res: any) => {
        if (res.d) {
            const reportResult = JSON.parse(res.d);
            let reportArray: any = [];
            await reportResult.forEach((value: any) => {
                let reportObj: any = {};
                reportObj["value"] = value["posName"];
                reportObj["label"] = value["posName"];
                reportArray.push(reportObj);
            })
            setFetchPos(reportArray);
        }
    }).catch((err: any) => {
        console.log('Error--->', err);

    })
  }
  const FetchData = async (body: any = '') => {
    debugger;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let data  = {
      location: (locations ? locations : ""),
      position: (postion ? postion : "")
    }

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(data)
    };
    await fetch(config().API_URL+"/GetCareersByLocPos", requestOptions)
      .then(res =>res.json())
      .then(async (res: any) => {
        if (res.d) {
          const reportResult = JSON.parse( res.d);
          setDataSource(reportResult);
        }
      }).catch((err: any) => {
        console.log('Error--->', err);
      })
  }
  const onFinish = async(values: any) => {
    console.log('Success:', values);
    if(values.name && values.email && fileupload && values.mobile) {
      var formdata = new FormData();
      await formdata.append("file", fileupload);
      var requestOptions1 = {
        method: 'POST',
        body: formdata
      };

await fetch(config().UPLOAD_URL+"fileUpload.ashx", requestOptions1)
.then(response => response.text())
.then(async(result) => {
        var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    "name": values.name ,
    "email": values.email,
    "files":result,
    "mobile":values.mobile,
    "careers":job.Id,
    "position":job.position,
    "department": job.department,
    "location": job.location,
    "referID": job.referID
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw
  };
  debugger;
  await fetch(config().API_URL+"/SubmitCareersData", requestOptions)
  .then(resp =>resp.json())
  .then((res: any) => {
    if(res.d){
      alert("Resume Sent Successfully!!!");
      handleClose();

    } else{
      alert("Something Went Wrong");
    }
  }).catch((err:any)=>{
    alert("Something Went Wrong");
  })

});
    } else {
      alert("Validation Failed");
    }
  }
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  const FetchLocation = async (body: any = '') => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };
    await fetch(config().API_URL + "/GetLocations", requestOptions)
            .then(response => response.json())
            .then(async (res: any) => {
                if (res.d) {
                    const reportResult = JSON.parse(res.d);
                    let reportArray: any = [];
                    await reportResult.forEach((value: any) => {
                        let reportObj: any = {};
                        reportObj["value"] = value["locName"];
                        reportObj["label"] = value["locName"];
                        reportArray.push(reportObj);
                    })
                    setFetchLoc(reportArray);
                }
            }).catch((err: any) => {
                console.log('Error--->', err);

            })
  }
  useEffect(() => {
    FetchData();
    FetchPosition();
    FetchLocation();
  }, [])
  return (<React.Fragment>

    <NavBarHeader></NavBarHeader>
    <section className={styles.breadcrumbs}>
      <div className="container">

        <div className="d-flex justify-content-between align-items-center">
          <h2 className={styles.headingH2}>Current Openings</h2>
          <Breadcrumb>
            <Breadcrumb.Item href="#">Company</Breadcrumb.Item>
            <Breadcrumb.Item active>Current Openings</Breadcrumb.Item>
          </Breadcrumb>
        </div>


      </div>

    </section>
    <section >

      <div className="container  mb-4">
        <div className="row">
          <div className="col-lg-12 py-2">

            <div className='row'>
              <div className="col-lg-3 col-6">
                <Select showSearch options={fetchPos} style={{ "width": "100%" }} placeholder="Position" onChange={(value: any) => setPosition(value)} />
              </div>
              <div className="col-lg-3 col-6">
                <Select showSearch options={fetchLoc} style={{ "width": "100%" }} placeholder="Location" onChange={(value: any) => setLocation(value)} />
              </div>
              <div className="col-lg-4 col-6" onClick={() => FetchData()}>
                <Tooltip title="search">
                  <Button shape="circle" icon={<SearchOutlined />} />
                </Tooltip>
              </div>
              <div className="col-lg-12 mt-3">
                <style type="text/css">
                  {`
    .accordion-button:not(.collapsed) {
      background-color: #fff;
      color: white;
    }
    .list-group-item{
      border: none;
    }
    .roItem{
      font-family: 'Abel', sans-serif !important;
      font-size:18px;
    }
    .fw-bold
    {
      font-family:"Abel", sans-serif
    }
    .accordion-body {
      font-family: 'Abel', sans-serif !important;
      font-size:18px;
    }
    `}
                </style>
                <Accordion style={{ "background": "#FFF !important" }}>
                  {Array.isArray(dataSource) && dataSource?.map((value: any, index: number) => {
                    return (<Accordion.Item eventKey={index.toString()} key={index}>
                      <Accordion.Header style={{ "background": "#FFF !important" }}>
                        <ol className="list-group" style={{ "width": "100%" }}>
                          <li className="list-group-item d-flex justify-content-between align-items-start" style={{ "width": "100%" }}>
                            <div className="row roItem" style={{ "width": "100%" }}>
                              <div className="col-lg-4 col-6">
                                <div className="fw-bold">{value.position}</div>
                                Ref ID: {value.referID}
                              </div>
                              <div className="col-lg-3 col-6">
                                <div className="fw-bold">Location</div>
                                {value.location}
                              </div>
                              <div className="col-lg-3 col-6">
                                <div className="fw-bold">Department</div>
                                {value.department}
                              </div>
                              <div className="col-lg-2 col-6">
                                <button type="button" className="btn btn-primary" onClick={() => handleShow(value)}>Apply Now</button>
                              </div>
                            </div>
                          </li>

                        </ol></Accordion.Header>
                      <Accordion.Body>
                        <div className="row ">
                          <div className="col-lg-12 col-12">

                            <h3 className={styles.aboutH3}>Overview</h3>
                            <div dangerouslySetInnerHTML={{
                              __html: value.jobDescr.
                                replace(new RegExp('\r?\n', 'g'), '<br />')
                            }}></div>
                            <br />
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>)
                  })}

                </Accordion>
              </div>
            </div>         {/* <p className={styles.aboutp}><b>ECIL Rapiscan Ltd,</b> incorporated in the year 1995(and is a Joint Venture Company formed jointly by OSI Systems, Inc., USA and Electronics Corporation of India Limited, a wholly-owned Government of India Enterprise)</p> */}

          </div>

        </div>
      </div>
      <footer id={styles1.footer}>
        <div className={styles1.footerTop}>
          <div className="container">
            <div className="row">
              <FooterWeb></FooterWeb>
              <div className={styles1.container}>
                <div className={styles1.copyright}>
                  © 2021 <strong><span>ECIL Rapiscan Limited.</span></strong> All Rights Reserved
                </div>

              </div>
            </div>
          </div>
        </div>
      </footer>
    </section>


    <ScrollToTop></ScrollToTop>
    <>


      <Modal show={show} onHide={handleClose}  size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header closeButton>
          <Modal.Title><h5>Apply for <b>{job?.position}</b></h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form  labelCol={{ span: 4 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
      wrapperCol={{ span: 14 }}>
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="email" label="Email"  rules={[{ required: true,type: "email",
                message: "The input is not valid E-mail!" }]}>
              <Input />
            </Form.Item>
            <Form.Item name="mobile" label="Mobile" rules={[{  
            pattern: new RegExp("^[0-9]{10}$"),
            message: "Wrong format!" }]}>
              <Input />
            </Form.Item>
            <Form.Item
        name="upload"
        label="Upload"
       

      >
       <input type="file" onChange={(event:any)=>{
         console.log("EVENT---->",event);
         setfileUpload(event.target.files[0])
         }}/>
      </Form.Item>
            <Form.Item wrapperCol={{ offset:4, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
  
          </Form></Modal.Body>

      </Modal>
    </>
  </React.Fragment>

  );
}

export default Opening;