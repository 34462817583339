import React, {useRef, useState} from 'react';
import { openNotificationWithIcon } from '../../common';
import { config } from '../../constant';
 const ContactForm = ()=>{
   const [contact, updateContact] = useState({
    name:"", email:"", enqType:"", subject:"", description:""
   });
   const form = useRef<any>(null);
   const ValidateEmail = (mail:any) =>
{
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
  {
    return (true)
  }
    alert("You have entered an invalid email address!")
    return (false)
}
   const submitContact = async() => {
     if(contact.name && contact.email && contact.enqType && contact.subject && contact.description){
if(ValidateEmail(contact.email)){
        var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify(contact);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw
  };

  await fetch(config().API_URL+"/SubmitEnquiries", requestOptions)
  .then(resp =>resp.json())
  .then((res: any) => {
    if(form.current ){
      form.current?.reset();
    }
    if(res.d){
      alert( "Enquiry Sent Successfully!!!");


    } else{
      alert( "Something Went Wrong!!!");

    }
  }).catch((err:any)=>{
    alert( "Something Went Wrong!!!");
  })
}

     } else {
      alert("Please Input all Fields");
     }
   }
    return(<>
         <div className="col-lg-12 mb-5"  id="contacts">
                <h2>QUICK CONTACT</h2>
            <form ref={form} className="php-email-form">
              <div className="row">
                <div className="form-group col-md-6">

                  <input type="text" onChange={(event)=>updateContact({...contact, name: event.target.value})} name="name" placeholder="Name" className="form-control" id="name" required={true}/>
                </div>
                <div className="form-group col-md-6">

                  <input type="email" className="form-control"  placeholder="Email" name="email" id="email" 
                  onChange={(event)=>updateContact({...contact, email: event.target.value})}
                  required={true}/>
                </div>
              </div>
              <select className="form-select col-md-6" 
                onChange={(event)=>{
                  debugger;
                  updateContact({...contact, enqType: event.target.value})}}
              aria-label="Default select example" required>
  <option value="" selected>--Select--</option>
  <option value="Sales" >Sales</option>
  <option value="Services">Services</option>
</select>
              <div className="form-group">

                <input type="text" className="form-control"   placeholder="Subject" name="subject"
                onChange={(event)=>updateContact({...contact, subject: event.target.value})}
                id="subject" required={true}/>
              </div>
              <div className="form-group">

                <textarea className="form-control"  placeholder="Enter your message"  name="message"
                onChange={(event)=>updateContact({...contact, description: event.target.value})}
                rows={5} required={true}></textarea>
              </div>
 
              <div className="text-center">
                <button type="button" className="btn  btn-outline-light my-2" onClick={submitContact}>Submit</button>
                </div>
            </form>
          </div></>)
}


export default ContactForm;