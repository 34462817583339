import { Button, Checkbox, Form, Input, notification } from 'antd';

import React from 'react';
import { useHistory } from 'react-router-dom';
import { config } from '../../constant';
const LoginContainer = () => {
	const router = useHistory();

	const onFinish = async (values: any) => {
	
		console.log('Success:', values);
		if (values.username && values.password) {
			var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");
	
			var raw = JSON.stringify({
				"username": values.username,
				"password": values.password
			});
	
			var requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: raw,
			};
			await fetch(config().API_URL+"/Login", requestOptions).then(response => response.json()).then((res: any) => {
				debugger;
				console.log(res);
				if(res.d){
					const respResult= JSON.parse(res.d);
					if(Array.isArray(respResult) && respResult.length===0){
						openNotificationWithIcon("Error", "Authentication Failed", "Invalid Username or Password");
					} else if(Array.isArray(respResult) && respResult.length>0){
						openNotificationWithIcon("Success", "Login Successful!!!", "");
						router.push('/dashboard/annualReport')
					}
			
				} else {
					openNotificationWithIcon("Error", "Error", "Something Went Wrong");
				}
			}).catch((err:any)=>{
				console.log('Error--->',err);
			})
		}
	};
	const openNotificationWithIcon = (status: string, title: string, descr: string) => {
		if (status === "Error") {
			notification.error({
				message: title,
				description: descr
			});
		} else  if (status === "Success") {
			notification.success({
				message: title,
				description: descr
			});
		}

	};

	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
		openNotificationWithIcon("Error", "Login Failed", "Please validate all fields");
	};

	return (
		<div className="login-page">
			<div className="login-box">

				<Form
					name="login-form"
					initialValues={{ remember: true }}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
				>
					<p className="form-title">Welcome back</p>
					<p>Login to the Dashboard</p>
					<Form.Item
						name="username"
						rules={[{ required: true, message: 'Please input your username!' }]}
					>
						<Input
							placeholder="Username"
						/>
					</Form.Item>

					<Form.Item
						name="password"
						rules={[{ required: true, message: 'Please input your password!' }]}
					>
						<Input.Password
							placeholder="Password"
						/>
					</Form.Item>

					<Form.Item name="remember" valuePropName="checked">
						<Checkbox>Remember me</Checkbox>
					</Form.Item>

					<Form.Item>
						<Button type="primary" htmlType="submit" className="login-form-button">
							LOGIN
						</Button>
					</Form.Item>
				</Form>
			</div>
		</div>
	);
}


export default LoginContainer;