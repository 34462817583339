import React, { useEffect, useState } from 'react';
import { Accordion, Breadcrumb, Button } from 'react-bootstrap';
import styles from './report.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBarHeader } from '../../components/navbar';
import { ScrollToTop } from '../../components/scrolltotop';
import { FooterWeb } from '../../components/footer';
import styles1 from '../../components/footer/footer.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Select } from 'antd';
import moment from 'moment';

import { faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { config } from '../../constant';
const CSR = () => {
    const [dataSource, setDataSource] = useState<Array<any>>([]);
    const [year,setYear] = useState<Array<any>>();
    const FetchData = async (body:any='') => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let url =''
        if(body && body!=="All"){
            url = config().API_URL+"/GetCSRReportYear?year="+body;
        } else {
            url = config().API_URL+"/AddCSRreport";
        }


        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
        };
        await fetch(url, requestOptions).then(response=>response.json()).then(async (res: any) => {
            if (res.d) {
                const respResult= JSON.parse(res.d);
        if(Array.isArray(respResult)){
        
            const year:any =  _.groupBy(respResult, 'year');
           const finalArray:any =  Object.keys(year).map((value:any)=>{
                const object:any ={
                    year:"",
                    files:[],
                    Id:0
                };
                year[value].forEach((values:any)=>{
                    object.year = value;
                    object.files.push(values.files);
                    object.Id = values.Id;
                })
                return object;
            });
            const finalValues = finalArray.sort((a:any,b:any)=>{return (b.year-a.year)});
            setDataSource(finalValues);
        }
            }
        }).catch((err: any) => {
            console.log('Error--->', err);
        })
    }
    useEffect(() => {
        FetchData();
        var date = new Date();
        var year = date.getFullYear();
        var option = ["All"];
        for (var i:any = year - 10; i <= year + 3; i++) {
            option.push(i);
          }
          setYear(option.sort((a:any,b:any)=>b-a));
    }, [])

    const handleChange = (value:any) =>{
        console.log(value.key); // { value: "lucy", key: "lucy", label: "Lucy (101)" }
        if(value.key==="All"){
            FetchData();
        } else {
            if(year && year[value.key])
            FetchData(year[value.key]|| '');
        }
        
      }
      const download_files =(files:any) => {
        var a:any = document.createElement('a');
        a.href = config().UPLOAD_URL+"/DownloadFile.ashx?id="+ files;
        a.target = '_blank';
                 if (a.click) {
          a.click(); // The click method is supported by most browsers.
        }
      }
    console.log('reportResult--->', moment().format('YYYY'));
    return (<><NavBarHeader></NavBarHeader>
        <section className={styles.breadcrumbs}>
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                    <h2 className={styles.headingH2}>Corporate Social Responsibility</h2>
                    <Breadcrumb className="d-none d-sm-block">
                        <Breadcrumb.Item href="#">Company</Breadcrumb.Item>
                        <Breadcrumb.Item>Corporate Social Responsibility</Breadcrumb.Item>

                    </Breadcrumb>
                </div>

            </div>
        </section>

        <section>
            <div className="container  mb-4">
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className={styles.aboutH3}>Recent CSR</h3>&nbsp;&nbsp;
                        <Select
                            labelInValue
                            defaultValue={{"value":"All"}}
                            style={{ width: 120 }}
                            onChange={handleChange}
                        >
                            {year?.map((value: any, index: number) => <Select.Option value={value} key={index}>{value}</Select.Option>)}                          

                        </Select>
                        <p className={styles.aboutp}>At ECIL-Rapiscan strives for upliftment and well-being of the society. Through our Corporate
Social Responsibility programme, we are committed in working towards the larger good of
the society security. Our day-to-day operations and our security product lines are integrated
with the social cause of good governance, maintenance of law and order and assistance of
government and public institutions in better administration.</p>
<p className={styles.aboutp}>Corporate Social Responsibility is the continuing commitment by business to behave ethically
and contribute to economic development while improving the quality of life of the workforce
and their families as well as of the local community and society at large
</p>
<p className={styles.aboutp}>
Below are the areas in which ECIL-Rapiscan Limited is engaged

</p>

<ul className={styles.tailMade}>
              <li><FontAwesomeIcon icon={faCheckDouble} className="text-primary"  /> <span>Eradication of hunger</span></li>
              <li><FontAwesomeIcon icon={faCheckDouble} className="text-primary"  /> <span> Contribution to PMCARES Fund</span></li>
              <li><FontAwesomeIcon icon={faCheckDouble} className="text-primary"  /> <span> Contribution to PM Relief Fund.</span></li>
</ul>

                        <Accordion defaultActiveKey="0">
                            {dataSource.map((value: any, index: number) => <Accordion.Item key={index} eventKey={index.toString()}>
                                <Accordion.Header>  <span className={`${styles.pHead} text-start  ${styles.textInfo}`}>{ value.year}</span>
                                    {/* <p className={`text-start  ${styles.pHeadS}`}>Chairman and CEO</p> */}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className="row ">
                                        <div className="col-12">
                                            <table className="table table-striped ">
                                                <thead className={styles.theadStyle}>
                                                    <th>Year</th>
                                                    <th>Download</th>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{value.year}</td>
                                                        <td>
                                                        {Array.isArray(value.files) && value.files.length>0 && value.files.map((values:any)=>{
  return(<><Button variant="outline-primary"  onClick={()=>download_files(values)}>
 <FontAwesomeIcon icon={faDownload} /> {values}
</Button><span>&nbsp;</span></>)
                  })}
                                                    
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>)}

                        </Accordion>
                    </div>
                </div>
            </div>
        </section>
        <footer id={styles1.footer}>
            <div className={styles1.footerTop}>
                <div className="container">
                    <div className="row">
                        <FooterWeb></FooterWeb>
                        <div className={styles1.container}>
                            <div className={styles1.copyright}>
                                © 2021 <strong><span>ECIL Rapiscan Limited.</span></strong> All Rights Reserved
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </footer>

        <ScrollToTop></ScrollToTop>
    </>)
};

export default CSR;
