import { Button, Form, Input, PageHeader, Space, Table } from 'antd';
import { useHistory , useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import DashBoardL from '../../../components/dashboardL';
import { openNotificationWithIcon } from '../../../common';
import { config } from '../../../constant';


const RegionsLoc = () => {
    const router = useHistory();
    const {id}:any = useParams();
    const [dataSource, setDataSource] = useState([]);
    const [token,setToken] = useState<string>('');
    const [form] = Form.useForm();
    const editLocation = async (id: string) => {
        router.push('/dashboard/regions/'+id);
    }
    const deletePositions = async (id: string) => {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

        
    
                var requestOptions:any = {
                    method: 'POST',
                    headers: myHeaders,
            body:JSON.stringify({
                id:id
            })
                };
            await fetch(config().API_URL+"/DeleteRegions", requestOptions)
            .then(response=>response.json())
            .then(async(res: any) => {

            if(res.d){
            openNotificationWithIcon("Success", "Region Deleted Successfully!!!", "");
            FetchData();
            }
        }).catch((err:any)=>{
        
            console.log('Error--->',err);
        })
    }
    const columns = [
        {
            title: 'Region',
            dataIndex: 'regName',
            key: 'regName',

        },      {
            title: 'Position',
            dataIndex: 'posName',
            key: 'posName',

        },
        {
            title: 'Action',
            key: 'action',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Button key="1" onClick={() => deletePositions(record.Id)} type="ghost" danger >

                        Delete
                    </Button>
                    <Button key="1" onClick={() => editLocation(record.Id)} type="primary" ghost  >

Edit
</Button>
                </Space>
            ),
        },
    ];
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const FetchData = async (body:any='') => {
        debugger;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var url = '';
        if(body){
            url = config().API_URL+"/SelectRegion?id="+body;
        } else {
            url = config().API_URL+"/GetRegions"
        }
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
        };
        await fetch(url,requestOptions).then(response=>response.json()).then(async (res: any) => {
            const result = JSON.parse(res.d);
            if (res.d && !body) {
                setDataSource(result);
            } else if(res.d && body) {
                const respResult = JSON.parse(res.d);
                if(Array.isArray(respResult) && respResult.length>0){
                    form.setFieldsValue({
                        regName: respResult[0].regName,
                        posName: respResult[0].posName
                      });
                }
                
            }
        }).catch((err: any) => {
            console.log('Error--->', err);
     
        })
    }
    useEffect(()=>{
        console.log('iddfdfdf--->',id);
        const token = localStorage.getItem('token')|| '';
        setToken(token);
        if(!id){
            FetchData(id);
        } else {
            FetchData();
            FetchData(id);
            
        }
        
      },[id,useParams])
    const onFinish = async (values: any) => {
        
        console.log('Success:', values);
        if(values.posName && values.regName){
            var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");

			var raw = JSON.stringify({
				"posName": values.posName,
                "regName":values.regName,
                "id": id
			});
            var url = '';
            if(id){
               url = config().API_URL+"/UpdateRegions";
            } else {
                url = config().API_URL+"/AddRegion";
            }
			var requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: raw
			};
			await fetch(url, requestOptions)
            .then((response)=>response.json()).then((res: any) => {
				if(res.d){
                    openNotificationWithIcon("Success", "Region Added Successfully!!!", "");
                    form.resetFields();
                    FetchData();
                    if(id){
                        router.push('/dashboard/regions');
                    }
                    
				} else if(res.status===200){
                    openNotificationWithIcon("Error", "Authentication Failed", "Invalid Username or Password");
				}
			}).catch((err:any)=>{
				console.log('Error--->',err);
     
			})
        } else {
            openNotificationWithIcon("Error", "Validation Failed", "Please Input all fields");
        }
    }
    return (<><DashBoardL>
        <><PageHeader
            className="site-page-header"

            title="Regions"
            subTitle="View"
        /> <Form
        form = {form}
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 4 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
                <Form.Item
                    label="Enter Region"
                    name="regName"
                    rules={[{ required: true, message: 'Enter Region' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Enter Position"
                    name="posName"
                    rules={[{ required: true, message: 'Enter Position' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ offset:4 , span: 4}}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form> <Table dataSource={dataSource} columns={columns} /></></DashBoardL></>)
}

export default RegionsLoc;