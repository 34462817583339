import React, { useState } from 'react';
import { Select, Tooltip, Button, Form, Input, Upload } from 'antd';
import { Accordion, Breadcrumb, ListGroup, Modal, Button as ButtonR } from 'react-bootstrap';
import TextArea from 'antd/lib/input/TextArea';
import { UploadOutlined } from '@ant-design/icons';
import { downloadURI, openNotificationWithIcon } from '../../common';
import { config } from '../../constant';
type ProductCustomerProps = {
    shows:string ,
    product:string,
    removeDownload:()=>void
}
const  ProductCustomer = ({shows,product,removeDownload}:ProductCustomerProps) =>{
    const [show, setShow] = useState(shows? shows:false);
    const handleClose = () => {setShow(false);removeDownload();}
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
      };


      const onFinish = async(values: any) => {
        console.log('Success:', values);
        if(values.name && values.email && values.mobile && values.description){
            var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");
      values.product = product;
			var raw = JSON.stringify(values);
	
			var requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: raw
			};
			await fetch(config().API_URL+"/CreateEnquiries", requestOptions)
      .then(res =>res.json()).then((res: any) => {
                debugger;
				 if(res.d){
                    downloadURI(shows,product?product:"Sample");
                    removeDownload();
                    handleClose();
                    alert('Enquiry Sent Successfully!!!');

				} else {
          alert('Something went wrong!!!');

        }
			}).catch((err:any)=>{
        alert('Something went wrong!!!')
			});
        } else {
          alert('Validation Failed')

        }
      };
    return ( <Modal show={show?true:false} onHide={handleClose}  size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered>
      <Modal.Header closeButton>
        <Modal.Title><h5>Enquiry on {product}</h5></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form  labelCol={{ span: 4 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
    wrapperCol={{ span: 14 }}>
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="email" label="Email"  rules={[{ required: true,type: "email",
              message: "The input is not valid E-mail!" }]}>
            <Input />
          </Form.Item>
          <Form.Item name="mobile" label="Mobile" rules={[{  
          pattern: new RegExp("^[0-9]{10}$"),
          message: "Wrong format!" }]}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description" rules={[{ required: true }]}>
          <TextArea rows={4} />
          </Form.Item>
          <Form.Item wrapperCol={{ offset:4, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>

        </Form></Modal.Body>

    </Modal>)
}

export default ProductCustomer;