import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { NavBarHeader } from '../../components/navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './clientlist.module.css';
import styles1 from '../../components/footer/footer.module.css';
import { FooterWeb } from '../../components/footer';
import { ScrollToTop } from '../../components/scrolltotop';
const ClientsList = () =>{
    return(<React.Fragment>
        <NavBarHeader></NavBarHeader>
        <section className={styles.breadcrumbs}>
        <div className="container">
 
 <div className="d-flex justify-content-between align-items-center">
     <h2 className={styles.headingH2}>Clients</h2>
     <Breadcrumb>
         <Breadcrumb.Item href="#">Company</Breadcrumb.Item>
         <Breadcrumb.Item active>Clients</Breadcrumb.Item>
     </Breadcrumb>
 </div>

</div>

        </section>
        <section className={styles.resume}>

            <div className="container  my-4">

            <div className={styles.main}>
<h2>GOVERNMENT</h2>
</div>
<div className="row">
<div className="col-lg-6 text-center">
    <img src="/images/clients/image001.gif" width="500" className="img-fluid"/>
</div>
<div className="col-lg-6 text-center">
    <img src="/images/clients/image003.png" className="img-fluid" width="300"/>
</div>
<div className="col-lg-6 text-center">
    <img src="/images/clients/image005.png" width="400" className="img-fluid"/>
</div>
<div className="col-lg-6 text-center">
    <img src="/images/clients/image007.png" width="300" className="img-fluid"/>
</div>
<div className="col-lg-6 text-center">
    <img src="/images/clients/image008.png" className="img-fluid" width="300"/>
</div>
<div className="col-lg-6 text-center">
    <img src="/images/clients/image010.png" width="300" className="img-fluid"/>
</div>
<div className="col-lg-6 text-center">
    <img src="/images/clients/image012.png" className="img-fluid" width="300"/>
</div>
<div className="col-lg-6 text-center">
    <img src="/images/clients/image013.png" width="300" className="img-fluid"/>
</div>
<div className="col-lg-6 text-center p-4">
    <img src="/images/clients/image014.png" className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image015.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center p-4">
    <img src="/images/clients/image016.png" className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image017.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center p-4">
    <img src="/images/clients/image019.png" className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image020.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center p-4">
    <img src="/images/clients/image022.jpg" className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image023.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center p-4">
    <img src="/images/clients/image024.png" className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image025.gif"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center p-4">
    <img src="/images/clients/image026.png" className="img-fluid" width="200"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image028.jpg"  className="img-fluid"  width="175"/>
</div>
<div className="col-lg-6 text-center p-4">
    <img src="/images/clients/image030.png" className="img-fluid" width="200"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image032.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center p-4">
    <img src="/images/clients/image033.png" className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image034.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center p-4">
    <img src="/images/clients/image035.png" className="img-fluid" height="200"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image036.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center p-4">
    <img src="/images/clients/image037.png" className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image038.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center p-4">
    <img src="/images/clients/image039.png" className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image041.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center p-4">
    <img src="/images/clients/image043.png" className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image044.png"  className="img-fluid"  width="200"/>
</div>
<div className="col-lg-6 text-center p-4">
    <img src="/images/clients/image046.jpg" className="img-fluid"   width="250"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image048.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center p-4">
    <img src="/images/clients/image049.png" className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image050.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center p-4">
    <img src="/images/clients/image051.jpg" className="img-fluid" width="250"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image053.gif"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center p-4">
    <img src="/images/clients/image054.jpg" className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image055.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center p-4">
    <img src="/images/clients/num-87.jpeg" className="img-fluid" width="250"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image057.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center p-4">
    <img src="/images/clients/image058.png" className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image060.png"  className="img-fluid"  width="250"/>
</div>
<div className="col-lg-6 text-center p-4">
    <img src="/images/clients/image062.png" className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image063.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center p-4">
    <img src="/images/clients/image065.png" className="img-fluid"   width="250"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image067.png"  className="img-fluid"/>
</div>
<div className={styles.main}>
<h2>AIRPORTS</h2>
</div>
<div className="col-lg-6 text-center p-4">
    <img src="/images/clients/image068.png" className="img-fluid"/>
</div>

<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image069.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center p-4">
    <img src="/images/clients/image072.jpg" className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image073.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center p-4">
    <img src="/images/clients/image075.png" className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image076.png"  className="img-fluid"/>
</div>
<div className={styles.main}>
<h2>RAILWAYS SECTOR</h2>
</div>
<div className="col-lg-6 text-center p-4">
    <img src="/images/clients/image077.png" className="img-fluid"/>
</div>

<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image078.jpg"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center p-4">
    <img src="/images/clients/image080.png" className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image083.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image084.png"  className="img-fluid"/>
</div>
<div className={styles.main}>
<h2>LOGISTICS</h2>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image085.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image086.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image087.jpg"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/img-blue-dart.jpeg"  className="img-fluid" width="200"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image091.jpg"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image093.jpg"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image094.jpg"  className="img-fluid"  width="200"/>
</div>
<div className={styles.main}>
<h2>CORPORATE</h2>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image095.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image096.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image098.jpg"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image099.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image100.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image101.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image103.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image104.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image106.jpg"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image107.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image108.jpg"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image110.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image112.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image113.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image114.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image115.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image116.png"  className="img-fluid"/>
</div>
<div className={styles.main}>
<h2>HOTELS</h2>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image117.png"  className="img-fluid"/>
</div>

<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image119.jpg"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image120.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image121.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image122.png"  className="img-fluid"/>
</div>
<div className="col-lg-6 text-center  p-4">
    <img src="/images/clients/image123.png"  className="img-fluid"/>
</div>
</div>
</div>
</section>
<footer id={styles1.footer}>
        <div className={styles1.footerTop}>
          <div className="container">
            <div className="row">
              <FooterWeb></FooterWeb>
              <div className={styles1.container}>
                <div className={styles1.copyright}>
                  © 2021 <strong><span>ECIL Rapiscan Limited.</span></strong> All Rights Reserved
                </div>

              </div>
            </div>
          </div>
        </div>
      </footer>

      <ScrollToTop></ScrollToTop>
</React.Fragment>);
};

export default ClientsList;