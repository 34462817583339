import { Layout, Menu} from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  TableOutlined,
  BookOutlined,
  ProfileOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import React, { ReactChild, ReactChildren, useState } from 'react';
import SubMenu from 'antd/lib/menu/SubMenu';

const { Header, Sider, Content } = Layout;

interface DashBoardLProps {
    children: ReactChild | ReactChildren;
  }
const DashBoardL = ({ children }: DashBoardLProps) => {
const [collapsed, setCollapsed] = useState<boolean>(false);


  const toggle = () => {
    setCollapsed(
        !collapsed
   );
  };


    return (
      <Layout>
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="logo" />
          <Menu theme="dark" mode="inline"   inlineCollapsed={false}      style={{   minHeight: 820}}>
          <Menu.Item key="10" icon={<TableOutlined />}>  
            <Link to={'/dashboard/matrixInput'}>Matrix Input</Link>
            </Menu.Item>
            <SubMenu key="sub3"  icon={<BookOutlined />} title="Corporate Governance">
            <Menu.Item key="1" >  
            <Link to={'/dashboard/annualReport'}>Annual Report</Link>
            </Menu.Item>
            <Menu.Item key="2" >  
            <Link to={'/dashboard/csr'}>Corporate Social Responsibility</Link>
            </Menu.Item>
              </SubMenu>  
            <SubMenu key="sub2"  icon={<ProfileOutlined />} title="Contacts">
            <Menu.Item key="9" >   <Link to={'/dashboard/regions'}>Regions</Link></Menu.Item>
            <Menu.Item key="10" ><Link to={'/dashboard/contacts'}>Contact</Link></Menu.Item>

          </SubMenu>
  
            <SubMenu key="sub1"  icon={<ProfileOutlined />} title="Careers">
            <Menu.Item key="5" >   <Link to={'/dashboard/career'}>View</Link></Menu.Item>
            <Menu.Item key="6"><Link to={'/dashboard/locations'}>Location</Link></Menu.Item>
            <Menu.Item key="7" ><Link to={'/dashboard/department'}>Department</Link></Menu.Item>
            <Menu.Item key="8"><Link to={'/dashboard/positions'}>Position</Link></Menu.Item>
          </SubMenu>
        </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: toggle,
            })}
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
            }}
          >
              
            {children}
          </Content>
        </Layout>
      </Layout>
    );

}

export default DashBoardL;